import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import AddProduct from "./pages/AddProduct";
import HomeEdit from "./pages/HomeEdit";
import Orders from "./pages/Orders";
import { useEffect } from "react";
import { auth } from "./firebase/config";
import { useHistory } from "react-router-dom";
import WishList from "./pages/WishList";
import QuickView from "./pages/Quickview";
import ViewOrder from "./pages/ViewOrder";
import CancelOrder from "./pages/CancelOrder";
import { PrintOrder } from "./pages/PrintOrder";
import AbandonedCheckouts from "./pages/AbandonedCheckouts";
// import ListingMainPage from "./ListingMainPage/ListingMainPage";

function App() {
  const history = useHistory();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        history.push("/sign-in");
      }
    });
  }, []);
  return (
    <div className="App">
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} />
        <Main>
          <Route exact path="/dashboard" component={Home} />
          {/* <Route exact path="/listing" component={ListingMainPage} /> */}
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/wishlist" component={WishList} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/product" component={AddProduct} />
          <Route exact path="/edit" component={HomeEdit} />
          <Route exact path="/view" component={QuickView} />
          <Route exact path="/vieworder" component={ViewOrder} />
          <Route exact path="/cancelorder" component={CancelOrder} />
          <Route exact path="/printorder" component={PrintOrder} />
          <Route exact path="/abondendcheckout" component={AbandonedCheckouts} />
          {/* <Redirect from="*" to="/dashboard" /> */}
        </Main>
      </Switch>
    </div>
  );
}

export default App;
