import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Breadcrumb, Button, Card, Col, DatePicker, Dropdown, Input, Menu, Pagination, Row, Select, Space, Table, Tabs,Typography } from "antd"
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem"
import Column from "antd/lib/table/Column";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const { Title } = Typography;
const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const columns = [
    {
      title: "PRODUCT",
      dataIndex: "product",
      key: "name",
    },
    {
      title: "BRAND",
      dataIndex: "brand",
      key: "function",
    },
  
    {
      title: "PRODUCT PRICE",
      key: "product_price",
      dataIndex: "product_price",
    },
    {
      title: "SELLING PICE",
      key: "selling_price",
      dataIndex: "selling_price",
    },
    {
      title: "QUANTITY",
      key: "quantity",
      dataIndex: "quantity",
    },
  ];
const AbandonedCheckouts = ()=>{
    const items = 20;
  const [searchData, setsearchdata] = useState([]);
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const [currentPage, setcurrentPage] = useState(0);
  const [totalItem, settotalItems] = useState();
  const [selectedrows, setselectedrows] = useState([]);
  const [showcollectionmodal, setshowcollectionmodal] = useState(false);
  const [collectionGlasstypes, setcollectionGlasstypes] = useState([]);
  const dispatch = useDispatch();
    const tabledata = (data) => {
        let list = [];
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          list.push({
            ...element,
            key: element["id"],
          });
        }
        setProducts([...list]);
        setsearchdata([...list]);
      };
      const rowSelection = {
        selectedrows,
        onChange: (row) => {
          setselectedrows([...row]);
        },
        selections: [
          Table.SELECTION_ALL,
          Table.SELECTION_INVERT,
          Table.SELECTION_NONE,
        ],
      };
    return (<>
    <div className="tabled">
        <Row>
          <Breadcrumb>
            <BreadcrumbItem>
              <a href="/dashboard">Home</a>
            </BreadcrumbItem>
            <BreadcrumbItem>Abandoned Checkouts</BreadcrumbItem>
          </Breadcrumb>
        </Row>
        <Card>
          <Row justify="end">
            <Col xs={12} style={{ textAlign: "right" }}>
              <Button type="primary" onClick={() => history.push("/product")}>
                Add products
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <h5 style={{ color: "#1890ff" }}>{totalItem} Abandoned Checkouts</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Tabs
                defaultActiveKey="1"
                onChange={(e) => {
                  let qs = "";
                  if (e === "2") {
                    qs = "&active=1";
                  } else if (e === "3") {
                    qs = "&active=0";
                  } else if (e === "4") {
                    qs = "&sold=1";
                  } else if (e === "5") {
                    qs = "&archive=1";
                  }
                //   getproducts(qs).then((res) => {
                //     if (res["status"] === "SUCCESS") {
                //       tabledata(res["data"]["list"]);
                //     }
                //   });
                }}
              >
                <TabPane tab="All" key="1"></TabPane>
              </Tabs>
            </Col>
          </Row>
          <Row style={{ marginTop: "5px" }}>
            <Col xs={6}>
              <Input
                size="small"
                placeholder="Search checkouts..."
                className="search_product"
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  var items = searchData.filter((val) => {
                    return (
                      val["product_name"]
                        .toLowerCase()
                        .startsWith(e.target.value.toLowerCase()) ||
                      val["quantity"] == e.target.value ||
                      val["product_price"] == e.target.value ||
                      val["selling_price"] == e.target.value
                    );
                  });
                  setProducts([...items]);
                }}
              />
            </Col>
            <Col xs={6}>
              <Space direction="vertical" size={12}>
                <RangePicker
                  style={{
                    marginLeft: "5px",
                    height: "40px",
                    marginRight: "5px",
                  }}
                  onChange={(startDate, endDate) => {
                    if (endDate) {
                     
                    }
                  }}
                />
              </Space>
            </Col>
            {/* <Col xs={6}>
              <Select
                placeholder="Select filter"
                style={{ width: "100%" }}
                onChange={(e) => {
                  let qs = "";
                  if (e === "active") {
                    qs = "&active=1";
                  } else if (e === "inactive") {
                    qs = "&active=0";
                  }
                  getproducts(qs).then((res) => {
                    if (res["status"] === "SUCCESS") {
                      tabledata(res["data"]["list"]);
                    }
                  });
                }}
              >
                <OptGroup label="Order status">
                  <Option value="active">Active</Option>
                  <Option value="inactive">Inactive</Option>
                </OptGroup>
              </Select>
            </Col> */}
            <Col xs={3}>
              <Dropdown
                overlay={() => {
                  return (
                    <Menu
                      onChange={(e) => {
                        console.log(e);
                      }}
                    >
                      <Menu.Item
                        key="0"
                        onClick={() => {
                         
                        }}
                      >
                        Active
                      </Menu.Item>
                      <Menu.Item
                        key="1"
                        onClick={() => {
                         
                        }}
                      >
                        Inactive
                      </Menu.Item>
                      <Menu.Item
                        key="2"
                        onClick={() => {
                        //   getproducts("&sold=1").then((res) => {
                        //     if (res["status"] === "SUCCESS") {
                        //       tabledata(res["data"]["list"]);
                        //     }
                        //   });
                        }}
                      >
                        Sold
                      </Menu.Item>
                    </Menu>
                  );
                }}
                trigger={["click"]}
              >
                <Button
                  style={{ width: "100%" }}
                  className="border-raduis-right-0"
                  onClick={(e) => e.preventDefault()}
                >
                  STATUS <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
            <Col xs={3}>
              <Dropdown
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item
                        key="0"
                        onClick={() => {
                        //   getproducts("&order_name=ASC").then((res) => {
                        //     if (res["status"] === "SUCCESS") {
                        //       tabledata(res["data"]["list"]);
                        //     }
                        //   });
                        }}
                      >
                        Product title A-Z
                      </Menu.Item>
                      <Menu.Item
                        key="1"
                        onClick={() => {
                        //   getproducts("&order_name=DESC").then((res) => {
                        //     if (res["status"] === "SUCCESS") {
                        //       tabledata(res["data"]["list"]);
                        //     }
                        //   });
                        }}
                      >
                        Product title Z-A
                      </Menu.Item>
                      <Menu.Item
                        key="2"
                        onClick={() => {
                        //   getproducts("&order_date=ASC").then((res) => {
                        //     if (res["status"] === "SUCCESS") {
                        //       tabledata(res["data"]["list"]);
                        //     }
                        //   });
                        }}
                      >
                        Created (oldest first)
                      </Menu.Item>
                      <Menu.Item
                        key="3"
                        onClick={() => {
                        //   getproducts("&order_date=DESC").then((res) => {
                        //     if (res["status"] === "SUCCESS") {
                        //       tabledata(res["data"]["list"]);
                        //     }
                        //   });
                        }}
                      >
                        Created (newest first)
                      </Menu.Item>
                      <Menu.Item
                        key="4"
                        onClick={() => {
                        //   getproducts("&order_quantity=ASC").then((res) => {
                        //     if (res["status"] === "SUCCESS") {
                        //       tabledata(res["data"]["list"]);
                        //     }
                        //   });
                        }}
                      >
                        Quantity (highest first)
                      </Menu.Item>
                      <Menu.Item
                        key="5"
                        onClick={() => {
                        //   getproducts("&order_quantity=DESC").then((res) => {
                        //     if (res["status"] === "SUCCESS") {
                        //       tabledata(res["data"]["list"]);
                        //     }
                        //   });
                        }}
                      >
                        Quantity (lowest first)
                      </Menu.Item>
                    </Menu>
                  );
                }}
                trigger={["click"]}
              >
                <Button
                  style={{ width: "100%" }}
                  className="border-raduis-left-0 border-left-0"
                  onClick={(e) => e.preventDefault()}
                >
                  SORT <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Row style={{ marginTop: "5px" }}>
                <Col>
                  {selectedrows.length !== 0 ? (
                    <>
                      <Button>{selectedrows.length} selected</Button>
                      <Dropdown
                        trigger={["click"]}
                        overlay={() => {
                          return (
                            <Menu>
                              <Menu.Item
                                key="0"
                                onClick={() => {
                                
                                }}
                              >
                                Activate
                              </Menu.Item>
                              <Menu.Item
                                key="3"
                                onClick={() => {
                                 
                                }}
                              >
                                Inactive
                              </Menu.Item>
                             
                            </Menu>
                          );
                        }}
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <Button>
                            More Action <DownOutlined />
                          </Button>
                        </a>
                      </Dropdown>
                    </>
                  ) : null}
                </Col>
              </Row>
              <div className="table-responsive">
                <Table
                  dataSource={products}
                  pagination={false}
                  rowSelection={rowSelection}
                  className="ant-border-space"
                >
                  <Column
                    title="PRODUCT"
                    dataIndex="product_name"
                    key="product_name"
                    render={(val, value) => {
                      return (
                        <Avatar.Group>
                          <Avatar
                            className="shape-avatar"
                            shape="circle"
                            size={40}
                            src={value["primary_image"]}
                          ></Avatar>
                          <div className="avatar-info">
                            <Title level={5}>{value["product_name"]}</Title>
                            <p>{value["sub_title"]}</p>
                          </div>
                        </Avatar.Group>
                      );
                    }}
                  />
                  <Column
                    title="Date"
                    render={(val, value) => {
                      return (
                        <div className="author-info">
                          <Title level={5}>{value["brand_name"]}</Title>
                        </div>
                      );
                    }}
                  />
                   <Column
                    title="Placed By"
                    render={(val, value) => {
                      return (
                        <div className="ant-employed">
                          <Title level={5}>{value["quantity"]}</Title>
                        </div>
                      );
                    }}
                    sorter={(a, b) => {
                      return a.quantity - b.quantity;
                    }}
                  />
                  <Column
                    title="PRODUCT PRICE"
                    render={(val, value) => {
                      return (
                        <div className="author-info">
                          <Title level={5}>{value["product_price"]}</Title>
                        </div>
                      );
                    }}
                    sorter={(a, b) => {
                      return a.product_price - b.product_price;
                    }}
                  />
                 
                </Table>
              </div>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col xs={12}>
            <Pagination
              current={currentPage}
              defaultPageSize={items}
              defaultCurrent={currentPage}
              total={totalItem}
              onChange={(value) => {
                setcurrentPage(value - 1);
              }}
            />
          </Col>
        </Row>
      </div>
    </>)
}
export default AbandonedCheckouts;