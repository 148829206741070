import { SearchOutlined } from "@ant-design/icons";
import { Avatar, Breadcrumb, Button, Card, Col, Input, Pagination, Row, Space, Table } from "antd";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import Column from "antd/lib/table/Column";
import Title from "antd/lib/typography/Title";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setwishlist } from "../app/features/counter";
import { httpGet } from "../app/networkUtils/networkUtils";


const WishList = () => {
    const wishlists = useSelector((state) => state.addproductform.wishlist);
    const dispatch = useDispatch();
    const items = 20;
    const [currentPage, setcurrentPage] = useState(1);
    const [totalItem, settotalItems] = useState();
    const history = useHistory();
    useEffect(() => {
        getproducts().then((res) => {
            if (res["status"] === "SUCCESS") {
                settotalItems(res["data"]["total"]);
                dispatch(setwishlist(res["data"]["list"]));
            }
        });
    }, [])

    const getproducts = () => {
        return new Promise((resolve, reject) => {
            httpGet(`products/wishlist?limit=10&offset=${currentPage - 1}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((Err) => {
                    reject(Err);
                });
        });
    };
    return <>
        <div className="tabled">
            <Row>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <a href="/dashboard">Home</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        WishList
                    </BreadcrumbItem>
                </Breadcrumb>
            </Row> <Card
                bordered={false}
            >
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Row justify="center">
                            <Col xs={12}>
                                <Input
                                    placeholder="Search products..."
                                    prefix={<SearchOutlined />}
                                    width="100%"
                                    style={{ marginRight: "30px" }}
                                />
                            </Col>
                            <Col xs={12} style={{ textAlign: "right" }}>
                                <Button
                                    type="primary"
                                    onClick={() => history.push("/product")}
                                >
                                    Add products
                                </Button></Col>
                        </Row>

                        <div className="table-responsive">
                            <Table
                                dataSource={wishlists}
                                pagination={false}
                                className="ant-border-space"

                            >
                                <Column title="PRODUCT" render={(text, value) => (
                                    <Avatar.Group>
                                        <Avatar
                                            className="shape-avatar"
                                            shape="circle"
                                            size={40}
                                            src={value["primary_image"]}
                                        ></Avatar>
                                        <div className="avatar-info">
                                            <Title level={5}>{value["name"]}</Title>
                                        </div>
                                    </Avatar.Group>
                                )} />

                                <Column title="MODAL NUMBER" render={(text, value) => (
                                    <div className="author-info">
                                        <Title level={5}>{value["modal_number"]}</Title>
                                    </div>
                                )} />
                                <Column title="USER NAME" render={(text, value) => (
                                    <div className="author-info">
                                        <Title level={5}>{value["user_name"]}</Title>
                                    </div>
                                )} />
                                <Column title="PRODUCT PRICE" render={(val, value) => {
                                    return <div className="author-info">
                                        <Title level={5}>{value["product_price"]}</Title>
                                    </div>
                                }}
                                    sorter={(a, b) => {
                                        return a.product_price - b.product_price
                                    }}
                                />
                                <Column title="SELLING PRICE" render={(val, value) => {
                                    return <div className="author-info">
                                        <Title level={5}>{value["selling_price"]}</Title>
                                    </div>
                                }} />
                                <Column title="USER NAME" render={(text, value) => (
                                    <div className="author-info">
                                        <Title level={5}>{value["user_name"]}</Title>
                                    </div>
                                )} />
                                <Column title="USER NUMBER" render={(text, value) => (
                                    <div className="ant-employed">
                                        <Title level={5}> {value["mobile_number"]} </Title>
                                    </div>
                                )} />
                                <Column
                                    title="Action"
                                    key="action"
                                    render={(text, record) => (
                                        <Space size="middle">
                                            <a>Quick View</a>
                                            <a>Delete</a>
                                        </Space>
                                    )}
                                />
                            </Table>
                        </div>

                    </Col>
                </Row>
            </Card>
            <Row>
                <Col xs={12}>
                    <Pagination current={currentPage} defaultPageSize={items} defaultCurrent={currentPage} total={totalItem} onChange={(value) => {
                        setcurrentPage(value - 1)
                    }} /></Col>
            </Row>
        </div>
    </>
}
export default WishList;