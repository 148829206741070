import { Col, Modal, Row } from "antd";
import Title from "antd/lib/typography/Title";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { setshowquickview, setshowshippingaddress } from "../app/features/counter";

const ShippingaddressModal = () => {
    const getdata = useSelector((state) => state.addproductform.shippingaddress);
    const showmodal = useSelector((state) => state.addproductform.viewshippingaddrsss);
    const dispatch = useDispatch();
    const handleOk = () => {
        dispatch(setshowshippingaddress(false))
    }
    const handleCancel = () => {
        dispatch(setshowshippingaddress(false))
    }
    return (<>
        <Modal width={700} style={{ top: 20 }}
            visible={showmodal} onOk={handleOk} onCancel={handleCancel}>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">1</th>
                        <td>First Name</td>
                        <td>{getdata["first_name"]}</td>
                    </tr>
                    <tr>
                        <th scope="row">2</th>
                        <td>Last title</td>
                        <td>{getdata["last_name"]}</td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <td>Email</td>
                        <td>{getdata["email"]}</td>
                    </tr>
                    <tr>
                        <th scope="row">5</th>
                        <td>Mobile Number</td>
                        <td>{getdata["mobile_no"]}</td>
                    </tr>
                    <tr>
                        <th scope="row">6</th>
                        <td>Gender</td>
                        <td>{getdata["gender"]}</td>
                    </tr>
                    <tr>
                        <th scope="row">7</th>
                        <td>Address 1</td>
                        <td>{getdata["address1"]}</td>
                    </tr>
                    <tr>
                        <th scope="row">8</th>
                        <td>Address 2</td>
                        <td>{getdata["address2"]}</td>
                    </tr>
                    <tr>
                        <th scope="row">9</th>
                        <td>Zip code</td>
                        <td>{getdata["zip"]}</td>
                    </tr>
                    <tr>
                        <th scope="row">10</th>
                        <td>City</td>
                        <td>{getdata["city"]}</td>
                    </tr>
                    <tr>
                        <th scope="row">10</th>
                        <td>Country</td>
                        <td>{getdata["country"]}</td>
                    </tr>
                    <tr>
                        <th scope="row">10</th>
                        <td>State</td>
                        <td>{getdata["state"]}</td>
                    </tr>
                </tbody>
            </table>
        </Modal>
    </>)
}

export default ShippingaddressModal;