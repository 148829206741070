import React, { useEffect } from "react";
import { Input, Button, Spin, notification, Breadcrumb } from "antd";
import StyledAddProduct from "./StyledAddProduct";
import { Row, Col } from "antd";
import { Upload, Select, Modal } from "antd";
import { InputNumber } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Divider, Typography, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setlenstypename,
  setfeaturename,
  setcolorname,
} from "../app/features/counter";
import ImgCrop from "antd-img-crop";
import { httpGet, httpPost } from "../app/networkUtils/networkUtils";
import { storage } from "../firebase/config";
import imageCompression from "browser-image-compression";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import {
  BlockPicker,
  ChromePicker,
  MaterialPicker,
  SketchPicker,
} from "react-color";

const AddProduct = (props) => {
  const history = useHistory();
  const lenstypename = useSelector(
    (state) => state.addproductform.lenstypename
  );
  const featuretypeName = useSelector(
    (state) => state.addproductform.featuretypeName
  );
  const [spinning, setspinning] = useState(false);
  const [productName, setProductname] = useState();
  const [subtitle, setsubtitle] = useState("");
  const [modalname, setmodalname] = useState("");
  const [modalnumber, setmodalnumber] = useState("");
  const [productPrice, setproductPrice] = useState("");
  const [wholesaleprice, setwholesaleprice] = useState("");
  const [offerprice, setofferprice] = useState(0);
  const [sellingprice, setsellingprice] = useState("");
  const colorname = useSelector((state) => state.addproductform.colorname);
  const dispatch = useDispatch();
  const [colors, setColors] = useState([]);
  const [previewVisible, setpreviewVisible] = useState(false);
  const [previewImage, setpreviewImage] = useState();
  const [previewTitle, setpreviewTitle] = useState();
  const [fileList, setFileList] = useState([]);
  const [primaryImage, setprimaryImage] = useState([]);
  const [gender, setGender] = useState();
  const [kidscategory, setkidscategory] = useState();

  const [productDetailsinputs, setproductDetailsinputs] = useState([]);
  const [productDesc, setproductDesc] = useState("");
  const [quantity, setQuantity] = useState([
    {
      id: "0",
      size: null,
      color: null,
      quantity: "",
      colorcode: "",
    },
  ]);
  const [framemeasurementInput, setframemeasurementInput] = useState([]);
  const [specificationInputs, setspecificationInputs] = useState([]);
  const [brandName, setBrandName] = useState();
  const [brands, setBrands] = useState([]);
  const [frameshapeName, setframeshapeName] = useState();
  const [frameShapes, setFrameshapes] = useState([]);
  const [faceShapename, setfaceshapeName] = useState();
  var formEditdata;
  const [faceShapes, setfaceshapes] = useState([
    {
      id: "0",
      name: "Oval",
    },
    {
      id: "1",
      name: "Square",
    },
    {
      id: "2",
      name: "Diamond",
    },
    {
      id: "4",
      name: "Round",
    },
    {
      id: "5",
      name: "Heart",
    },
    {
      id: "6",
      name: "Traingle",
    },
  ]);
  const [sizes, setSizes] = useState([
    {
      id: "0",
      name: "Medium",
    },
    {
      id: "1",
      name: "Small",
    },
    {
      id: "2",
      name: "Large",
    },
    {
      id: "4",
      name: "XL",
    },
  ]);
  const [glasstypes, setglasstypes] = useState([
    {
      id: "0",
      name: "Sun Glass",
    },
    {
      id: "1",
      name: "Eye Glass",
    },
    {
      id: "2",
      name: "Contact Lens",
    },
    {
      id: "3",
      name: "Computer Glass",
    },
    {
      id: "4",
      name: "Protective Eyewear",
    },
    {
      id: "5",
      name: "Designer Glasses",
    },
    {
      id: "6",
      name: "Computer Glass",
    },
  ]);
  const [glasstypename, setglasstypename] = useState();
  const [lensTypesNames, setlenstypesNames] = useState([]);
  const [lensTypes, setLenstypes] = useState([]);
  const [materialName, setMaterialName] = useState();
  const [materials, setmaterials] = useState([]);
  const [featuresName, setFeaturesNames] = useState([]);
  const [features, setFeatures] = useState([]);
  const [colorcode, setcolorcode] = useState("#FFFFFF");
  const [showdiscardmodal, setshowdiscardmodal] = useState(false);
  const [showaddcolormodal, setshowaddcolormodal] = useState(false);
  const [confirmLoadingcolormodal, setconfirmLoadingcolormodal] =
    useState(false);
  const onchangeInput = (e, index, type) => {
    if (type === "details") {
      productDetailsinputs[index]["value"] = e.target.value;
    } else if (type === "frame") {
      framemeasurementInput[index]["value"] = e;
    } else if (type === "spec") {
      specificationInputs[index]["value"] = e.target.value;
    }
  };
  const addItem = (type, name) => {
    if (type === "brand") {
      if (brandName !== "") {
        var items = brands.find((val) => `${val["name"]}`.toLowerCase() === `${brandName}`.toLowerCase());
        if (items) {
          openNotificationWithIcon("success", "Brand already exist");
        } else {
          addnewbrand();
        }
      }
    } else if (type === "frame") {
      if (frameshapeName !== "") {
        var items = frameShapes.find((val) => `${val["name"]}`.toLowerCase() === `${frameshapeName}`.toLowerCase());
        if (items) {
          openNotificationWithIcon("success", "Frame shape already exist");
        } else {
          addframeshape();
        }
      }
    } else if (type === "lens") {
      if (lenstypename !== "") {
        var items = lensTypes.find((val) => `${val["name"]}`.toLowerCase() === `${lenstypename}`.toLowerCase());
        if (items) {
          openNotificationWithIcon("success", "Lens type already exist");
        } else {
          addlenstype();
        }
      }
    } else if (type === "material") {
      if (materialName !== "") {
        var items = materials.find((val) => `${val["name"]}`.toLowerCase() === `${materialName}`.toLowerCase());
        if (items) {
          openNotificationWithIcon("success", "Material already exist");
        } else {
          addmaterial();
        }
      }
    } else if (type === "feature") {
      if (featuretypeName !== "") {
        var items = features.find((val) => `${val["name"]}`.toLowerCase() === `${featuretypeName}`.toLowerCase());
        if (items) {
          openNotificationWithIcon("success", "Item already exist");
        } else {
          addfeatures();
        }
      }
    } else if (type == "color") {
      if (colorname !== "") {
        var items = colors.find((val) => `${val["name"]}`.toLowerCase() === `${colorname}`.toLowerCase());
        if (items) {
          openNotificationWithIcon("success", "Color already exist");
        } else {
          addcolor();
        }
      }
    }
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setpreviewImage(file.url || file.preview);
    setpreviewVisible(true);
    setpreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handlemodalCancel = () => setpreviewVisible(false);
  const onimageChange = async ({ fileList: fileData, type: type }) => {
    const storageref = storage.ref("product_images");
    if (type == "primary") {
      if (fileData.length != 0) {
        compressImage(fileData[0]).then((comfile) => {
          fileData[0]["status"] =
            "done";
          storageref
            .child(fileData[0].name)
            .put(comfile)
            .then(async (snapshopt) => {
              const imageUrl = await storageref
                .child(fileData[0].name)
                .getDownloadURL();
              fileData[0]["url"] =
                imageUrl;
              setprimaryImage(fileData);
            });
        });
      }
    } else {
      if (fileData.length != 0) {
        const file = fileData[0];
        file["status"] = "done";
        compressImage(file).then((comFile) => {
          storageref
            .child(file.name)
            .put(comFile)
            .then(async (snapshot) => {
              const imageUrl = await storageref
                .child(file.name)
                .getDownloadURL();
              file["url"] = imageUrl;
              fileList.push(file);
              setFileList([...fileList]);
            });
        });
      }
    }
  };
  const addform = (type) => {
    if (type === "specs") {
      setspecificationInputs([
        ...specificationInputs,
        {
          id: specificationInputs.length + 1,
          name: "",
          value: "",
        },
      ]);
    } else if (type === "frame") {
      setframemeasurementInput([
        ...framemeasurementInput,
        {
          id: framemeasurementInput.length + 1,
          name: "",
          value: "",
        },
      ]);
    } else if (type === "quantity") {
      setQuantity([
        ...quantity,
        {
          id: quantity.length + 1,
          size: "",
          color: "",
          quantity: "",
        },
      ]);
    } else {
      setproductDetailsinputs([
        ...productDetailsinputs,
        {
          id: productDetailsinputs.length + 1,
          name: "",
          value: "",
        },
      ]);
    }
  };
  const removeformItem = (type, index) => {
    if (type === "product") {
      productDetailsinputs.splice(index, 1);
      setproductDetailsinputs([...productDetailsinputs]);
    } else if (type === "frame") {
      framemeasurementInput.splice(index, 1);
      setframemeasurementInput([...framemeasurementInput]);
    } else if (type == "quantity") {
      quantity.splice(index, 1);
      setQuantity([...quantity]);
    } else {
      specificationInputs.splice(index, 1);
      setspecificationInputs([...specificationInputs]);
    }
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: "SUCCESS",
      description: msg,
    });
  };
  const { Option, OptGroup } = Select;
  const { TextArea } = Input;
  useEffect(() => {
    const { state } = props.location;
    if (state !== undefined) {
      getformdata(state.productID);
    } else {
      getcolors();
      getbrands();
      getframeshapes();
      getlenstypes();
      getmaterials();
      getfeatures();
      setframemeasurementInput([
        {
          id: "0",
          name: "Lens Width",
          value: "",
        },
        {
          id: "1",
          name: "Lens Height",
          value: "",
        },
        {
          id: "0",
          name: "Bridge Width",
          value: "",
        },
        {
          id: "1",
          name: "Temple Length",
          value: "",
        },
      ]);
      setproductDetailsinputs([
        {
          id: "0",
          name: "Size",
          value: "Large",
        },
        {
          id: "1",
          name: "Material",
          value: "Metal",
        },
      ]);
      setspecificationInputs([
        {
          id: "0",
          name: "Type",
          value: "Wayfarer Sunglasses",
        },
        {
          id: "1",
          name: "Feature",
          value: "UV Protected Lens",
        },
        {
          id: "3",
          name: "Style",
          value: "Full Rim",
        },
        {
          id: "4",
          name: "Frame Color",
          value: "Black",
        },
        {
          id: "5",
          name: "Warranty",
          value: "2 Years",
        },
        {
          id: "6",
          name: "Face Shape",
          value: "Oval",
        },
      ]);
    }

  }, []);

  const getformdata = (productID) => {
    httpGet(`products/formedit?id=${productID}`).then((res) => {
      if (res["status"] === "SUCCESS") {
        var data = res["data"];
        formEditdata = data;
        getcolors();
        getbrands();
        getframeshapes();
        getlenstypes();
        getmaterials();
        getfeatures();
        setProductname(data["name"]);
        setsubtitle(data["sub_title"]);
        setmodalname(data["modal_name"]);
        setmodalnumber(data["modal_number"]);
        setGender(data["gender"]);
        setfaceshapeName(data["face_shape"]);
        setproductPrice(data["product_price"]);
        setwholesaleprice(data["wholesale_price"]);
        setsellingprice(data["selling_price"]);
        setproductDesc(data["product_description"]);

        setprimaryImage([
          {
            uid: "1",
            name: "image.png",
            status: "done",
            url: data["primary_image"],
          },
        ]);

        var images = data["images"];
        const list = [];
        images.forEach((value) => {
          list.push({
            uid: "1",
            name: "image.png",
            status: "done",
            url: value,
          });
          setFileList([...list]);
        });
        setproductDetailsinputs([...data["product_details"]]);
        setframemeasurementInput([...data["frame_measurment"]]);
        setspecificationInputs([...data["specification"]]);
      }
    });
  };
  const getcolors = () => {
    httpGet("colors/get").then((res) => {
      if (res["status"] === "SUCCESS") {
        setColors([...res["data"]]);
        if (formEditdata !== undefined) {
          setQuantity([
            {
              id: "0",
              size: formEditdata["size"],
              color: res["data"].find((val) => val["id"] === formEditdata["color"])["name"],
              quantity: formEditdata["quantity"],
            },
          ]);
        }
      }
    });
  };
  const addcolor = () => {
    const body = {
      name: colorname,
      color_code: colorcode,
    };
    httpPost("colors/add", body).then((res) => {
      if (res["status"] === "SUCCESS") {
        setColors([...colors, { id: res["data"], name: colorname }]);
        setconfirmLoadingcolormodal(false);
        setshowaddcolormodal(false);
        openNotificationWithIcon("success", "Color added successfully");
      }
    });
  };
  const getframeshapes = () => {
    httpGet("products/getframeshapes").then((res) => {
      if (res["status"] === "SUCCESS") {
        setFrameshapes([...res["data"]]);
        if (formEditdata !== undefined) {
          setframeshapeName(res["data"].find((val) => val["id"] === formEditdata["frame_shape"])["name"]);

        }

      }
    });
  };
  const addframeshape = () => {
    const body = {
      name: frameshapeName,
    };
    httpPost("products/addframeshape", body).then((res) => {
      if (res["status"] === "SUCCESS") {
        setFrameshapes([
          ...frameShapes,
          { id: res["data"], name: frameshapeName },
        ]);
        setframeshapeName(frameshapeName);
      }
    });
  };
  const getlenstypes = () => {
    httpGet("products/getlenstypes").then((res) => {
      if (res["status"] === "SUCCESS") {
        setLenstypes([...res["data"]]);
        if (formEditdata !== undefined) {
          var names = [];
          formEditdata["lens_type"].forEach((el) => {
            var items = res["data"].find((val) => val["id"] === el);
            if (items) {
              names.push(items["name"]);
            }
          })
          setlenstypesNames([...names]);
        }
      }
    });
  };
  const addlenstype = () => {
    const body = {
      name: lenstypename,
    };
    httpPost("products/addlenstype", body).then((res) => {
      if (res["status"] === "SUCCESS") {
        setLenstypes([...lensTypes, { id: res["data"], name: lenstypename }]);
      }
    });
  };
  const getmaterials = () => {
    httpGet("products/getmaterials").then((res) => {
      if (res["status"] === "SUCCESS") {
        setmaterials([...res["data"]]);
        if (formEditdata !== undefined) {
          setMaterialName(res["data"].find((val) => val["id"] === formEditdata["material"])["name"]);
        }
      }
    });
  };
  const addmaterial = () => {
    const body = {
      name: materialName,
    };
    httpPost("products/addmaterial", body).then((res) => {
      if (res["status"] === "SUCCESS") {
        setmaterials([...materials, { id: res["data"], name: materialName }]);
      }
    });
  };
  const getfeatures = () => {
    httpGet("products/getfeatures").then((res) => {
      if (res["status"] === "SUCCESS") {
        setFeatures([...res["data"]]);
        if (formEditdata !== undefined) {
          var names = [];
          formEditdata["features"].forEach((el) => {
            var items = res["data"].find((val) => val["id"] === el);
            if (items) {
              names.push(items["name"]);
            }
          })
          setFeaturesNames([...names]);
        }
      }
    });
  };
  const addfeatures = () => {
    const body = {
      name: featuretypeName,
    };
    httpPost("products/addfeature", body).then((res) => {
      if (res["status"] === "SUCCESS") {
        setFeatures([...features, { id: res["data"], name: featuretypeName }]);
      }
    });
  };
  const getbrands = () => {
    httpGet("products/getbrands").then((res) => {
      if (res["status"] === "SUCCESS") {
        setBrands([...res["data"]]);
        if (formEditdata !== undefined) {
          setBrandName(res["data"].find((val) => val["id"] === formEditdata["brand"])["name"]);
        }
      }
    });
  };
  const addnewbrand = () => {
    const body = {
      name: brandName,
    };
    httpPost("products/addbrand", body).then((res) => {
      if (res["status"] == "SUCCESS") {
        setBrands([...brands, { id: res["data"], name: brandName }]);
      }
    });
  };
  const compressImage = async (imageFile) => {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(imageFile, options);
        resolve(compressedFile);
      } catch (error) {
        reject(error);
      }
    });
  };
  return (
    <StyledAddProduct>
      <Modal
        title="Discard changes?"
        visible={showdiscardmodal}
        onOk={() => {
          setshowdiscardmodal(false);
          history.goBack();
        }}
        onCancel={() => {
          setshowdiscardmodal(false);
        }}
        okText="OK"
        cancelText="Cancel"
      />
      <Modal
        title="Add new color"
        visible={showaddcolormodal}
        confirmLoading={confirmLoadingcolormodal}
        onOk={() => {
          addItem("color", colorname);
        }}
        onCancel={() => {
          setshowaddcolormodal(false);
        }}
        okText="SUBMIT"
        cancelText="Cancel"
      >
        <Row justify="center">
          <Col xs={24}>
            <Input
              value={colorname}
              onChange={(e) => {
                dispatch(setcolorname(e.target.value));
              }}
              className="product_inputs"
              placeholder="Color Name"
            />
            <br />
            <div style={{ marginTop: "5px" }}>
              <ChromePicker
                color={colorcode}
                onChangeComplete={(e) => {
                  setcolorcode(e.hex);
                }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
      <Row>
        <Breadcrumb>
          <BreadcrumbItem>
            <a
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              Home
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a
              onClick={() => {
                history.push("/tables");
              }}
            >
              Products
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>Add Products</BreadcrumbItem>
        </Breadcrumb>
      </Row>
      <br />
      <Spin tip="Loading..." spinning={spinning}>
        <Row>
          <Col>
            <p className="category-title" style={{ marginBottom: "0px" }}>
              {" "}
              Basic details
            </p>
          </Col>
        </Row>
        <Row>
          <Col style={{ padding: "0.5rem 0rem" }} xs={7}>
            <Input
              value={productName}
              onChange={(e) => {
                setProductname(e.target.value);
              }}
              className="product_inputs"
              placeholder="Product Name"
            />
          </Col>
          <Col style={{ padding: "0.5rem 0rem", marginLeft: "1rem" }} xs={7}>
            {" "}
            <Input
              value={subtitle}
              onChange={(e) => {
                setsubtitle(e.target.value);
              }}
              className="product_inputs"
              placeholder="Subtitle"
            />
          </Col>
          <Col style={{ padding: "0.5rem 0rem", marginLeft: "1rem" }} xs={7}>
            <Input
              value={modalname}
              onChange={(e) => {
                setmodalname(e.target.value);
              }}
              className="product_inputs"
              placeholder="Modal Name"
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ padding: "0.5rem 0rem" }} xs={7}>
            {" "}
            <Input
              value={modalnumber}
              onChange={(e) => {
                setmodalnumber(e.target.value);
              }}
              className="product_inputs"
              placeholder="model Number"
            />
          </Col>
          <Col style={{ padding: "0.5rem 0rem", marginLeft: "1rem" }} xs={7}>
            <Select
              showSearch
              className="product_inputs"
              placeholder="Select Brand"
              value={brandName}
              onSelect={(e) => {
                setBrandName(e);
              }}
              onSearch={(e) => {
                setBrandName(e);
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space align="center" style={{ padding: "0 8px 4px" }}>
                    <Input placeholder="Please enter brand" onChange={(e) => {
                      setBrandName(e.target.value)
                    }} />
                    <Typography.Link
                      onClick={() => {
                        addItem("brand");
                      }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <PlusOutlined /> Add new Brand
                    </Typography.Link>
                  </Space>
                </>
              )}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  const list = [];
                  brands.map((el) => {
                    if (
                      el["name"]
                        .toUpperCase()
                        .indexOf(e.target.value.toUpperCase()) > -1
                    ) {
                      list.push(el["name"]);
                    }
                  });
                  if (list.length === 0) {
                    addItem("brand", e.target.value);
                    setBrandName(e.target.value);
                  }
                }
              }}
            >
              {brands.map((value) => {
                return (
                  <Option key={value["id"]} value={value["name"]}>
                    {value["name"]}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col
            style={{
              padding: "0.5rem 0rem",
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
            }}
            xs={7}
          >
            <Select
              className="product_inputs"
              // showSearch
              placeholder="Select Gender/Kids"
              value={gender}
              onSelect={(e) => {
                setGender(e);
              }}
            >
              <OptGroup label="Gender">
                <Option key="1" value="Male">
                  Male
                </Option>
                <Option key="2" value="Female">
                  Female
                </Option>
                <Option key="3" value="Other">
                  Other
                </Option>
              </OptGroup>
              <OptGroup label="Kids">
                <Option key="4" value="Boy">
                  Boy
                </Option>
                <Option key="5" value="Girl">
                  Girl
                </Option>
              </OptGroup>
            </Select>
          </Col>
        </Row>
        {gender === "Boy" || gender === "Girl" ? (
          <Row>
            <Col xs={7}>
              <Select
                className="product_inputs"
                placeholder="Select kids category"
                value={kidscategory}
                onSelect={(e) => {
                  setkidscategory(e);
                }}
              >
                <Option key="6">TODDLERS AGE 0-3</Option>
                <Option key="7">LITTLE KIDS AGE 4-7</Option>
                <Option key="8">PRE-TEEN AGE 8-12</Option>
                <Option key="9">TEEN AGE 13+</Option>
              </Select>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col style={{ padding: "0.5rem 0rem" }} xs={7}>
            <Select
              showSearch
              className="product_inputs"
              placeholder="Select Frameshape"
              value={frameshapeName}
              onSearch={(e) => {
                setframeshapeName(e);
              }}
              onSelect={(e) => {
                setframeshapeName(e);
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space align="center" style={{ padding: "0 8px 4px" }}>
                    <Input placeholder="Please enter frameshape" onChange={(e) => {
                      setframeshapeName(e.target.value)
                    }} />
                    <Typography.Link
                      onClick={() => {
                        addItem("frame", frameshapeName);
                      }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <PlusOutlined /> Add new
                    </Typography.Link>
                  </Space>
                </>
              )}
            >
              {frameShapes.map((value) => {
                return (
                  <Option key={value["id"]} value={value["name"]}>
                    {value["name"]}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col
            style={{
              padding: "0.5rem 0rem",
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
            }}
            xs={7}
          >
            <Select
              showSearch
              className="product_inputs"
              placeholder="Select Faceshape"
              value={faceShapename}
              onSelect={(e) => {
                setfaceshapeName(e);
              }}
            >
              {faceShapes.map((value) => {
                return (
                  <Option key={value["id"]} value={value["name"]}>
                    {value["name"]}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col
            style={{
              padding: "0.5rem 0rem",
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
            }}
            xs={7}
          >
            <Select
              showSearch
              className="product_inputs"
              placeholder="Select GlassType"
              value={glasstypename}
              onSelect={(e) => {
                setglasstypename(e);
              }}
            >
              {glasstypes.map((value) => {
                return (
                  <Option key={value["id"]} value={value["name"]}>
                    {value["name"]}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <p className="category-title" style={{ marginBottom: "0px" }}>
              {" "}
              Quantity
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {quantity.map((value, index) => {
              return (
                <Row>
                  <Col style={{ padding: "0.5rem 0rem" }} xs={4}>
                    <Select
                      showSearch
                      className="product_inputs"
                      placeholder="Size"
                      value={value["size"]}
                      onChange={(e) => {
                        quantity[index]["size"] = e;
                        setQuantity([...quantity]);
                      }}
                      onSelect={(e) => {
                        quantity[index]["size"] = e;
                        setQuantity([...quantity]);
                      }}
                    >
                      {sizes.map((value) => {
                        return (
                          <Option key={value["id"]} value={value["name"]}>
                            {value["name"]}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col
                    xs={5}
                    style={{ padding: "0.5rem 0rem", marginLeft: "1rem" }}
                  >
                    <Select
                      showSearch
                      placeholder="Color picker"
                      className="product_inputs"
                      value={value["color"]}
                      onChange={(e) => {
                        quantity[index]["color"] = e;
                        setQuantity([...quantity]);
                      }}
                      onSearch={(e) => {
                        if (e !== "") {
                          dispatch(setcolorname(e));
                        }
                      }}
                      onSelect={(e) => {
                        quantity[index]["color"] = e;
                        setQuantity([...quantity]);
                      }}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space
                            align="center"
                            style={{ padding: "0 8px 4px" }}
                          >
                            <Typography.Link
                              onClick={() => {
                                setshowaddcolormodal(true);
                              }}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <PlusOutlined /> Add new color
                            </Typography.Link>
                          </Space>
                        </>
                      )}
                    >
                      {colors.map((value) => {
                        return (
                          <Option key={value["id"]} value={value["name"]}>
                            {value["name"]}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>

                  <Col
                    style={{
                      padding: "0.5rem 0rem",
                      marginLeft: "1rem",
                    }}
                    xs={7}
                  >
                    <InputNumber
                      onChange={(e) => {
                        quantity[index]["quantity"] = e;
                        setQuantity([...quantity]);
                      }}
                      value={value["quantity"]}
                      className="product_inputs"
                      placeholder="Quantity"
                    />
                  </Col>
                  {index !== 0 ? (
                    <Col xs={2} style={{ margin: " auto 5px" }}>
                      <Typography.Link
                        onClick={() => {
                          removeformItem("quantity", index);
                        }}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <MinusCircleOutlined />
                      </Typography.Link>
                    </Col>
                  ) : null}
                </Row>
              );
            })}
            <Typography.Link
              onClick={() => {
                addform("quantity");
              }}
              style={{ whiteSpace: "nowrap" }}
            >
              <PlusOutlined /> Add new form
            </Typography.Link>
          </Col>
        </Row>{" "}
        <br />
        <Row>
          <Col>
            <p className="category-title" style={{ marginBottom: "0px" }}>
              {" "}
              Price
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              padding: "0.5rem 0rem",
              display: "flex",
              alignItems: "center",
            }}
            xs={5}
          >
            <InputNumber
              value={wholesaleprice}
              pattern="[0-9]*"
              onChange={(e) => {
                if (e !== null) {
                  setwholesaleprice(e);
                }
              }}
              className="product_inputs"
              placeholder="Wholesale price"
            />
          </Col>
          <Col style={{ padding: "0.5rem 0rem", marginLeft: "1rem" }} xs={5}>
            <InputNumber
              value={productPrice}
              pattern="[0-9]*"
              onChange={(e) => {
                if (e !== null) {
                  setproductPrice(e);
                }
              }}
              className="product_inputs"
              placeholder="Product price"
            />
          </Col>
          <Col
            style={{
              padding: "0.5rem 0rem",
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
            }}
            xs={5}
          >
            <InputNumber
              value={sellingprice}
              pattern="[0-9]*"
              onChange={(e) => {
                if (e !== null) {
                  setsellingprice(e);
                  if (productPrice !== "" && sellingprice !== "") {
                    if (productPrice > sellingprice) {
                      let diffrent = parseInt(productPrice) - parseInt(e);
                      const offerprice =
                        (diffrent / parseInt(productPrice)) * 100;
                      setofferprice(Math.round(offerprice));
                    } else {
                      setofferprice(0);
                    }
                  } else {
                    setofferprice(0);
                  }
                }
              }}
              className="product_inputs"
              placeholder="selling price"
            />
          </Col>
          <Col
            style={{
              padding: "0.5rem 0rem",
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
            }}
            xs={5}
          >
            {offerprice > 0 ? <h6 style={{ color: "red" }}>{offerprice}% offer applied</h6> : ""}
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <p className="category-title" style={{ marginBottom: "0px" }}>
              {" "}
              Other details
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              padding: "0.5rem 0rem",
            }}
            xs={10}
          >
            <Row>
              <Col xs={16}>
                <Select
                  mode="multiple"
                  showSearch
                  value={lensTypesNames}
                  onChange={(e) => {
                    setlenstypesNames([...e]);
                  }}
                  onSearch={(e) => {
                    dispatch(setlenstypename(e));
                  }}
                  // onSelect={(e) => {
                  //   setlenstypesNames([...lensTypesNames, e]);
                  // }}
                  className="product_inputs"
                  placeholder="Lens type"
                >
                  {lensTypes.map((value) => {
                    return (
                      <Option key={value["id"]} value={value["name"]}>
                        {value["name"]}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col xs={8}>
                <Typography.Link
                  onClick={(e) => {
                    if (lenstypename !== "") {
                      var item = lensTypes.find((val) => `${val["name"]}`.toLowerCase() === `${lenstypename}`.toLowerCase());
                      if (item) {
                        openNotificationWithIcon("success", "Lens type already exist");
                      } else {
                         addItem("lens");
                        setlenstypesNames([...lensTypesNames, lenstypename]);
                      }

                    }

                  }}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <PlusOutlined /> Add item
                </Typography.Link>
              </Col>
            </Row>
          </Col>
          <Col style={{ padding: "0.5rem 0rem" }} xs={10}>
            <Select
              showSearch
              className="product_inputs"
              placeholder="Select Material"
              value={materialName}
              onSearch={(e) => {
                setMaterialName(e);
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space align="center" style={{ padding: "0 8px 4px" }}>
                    <Typography.Link
                      onClick={() => {
                        if (materialName != "") {
                          addItem("material", materialName);
                        }
                      }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <PlusOutlined /> Add item
                    </Typography.Link>
                  </Space>
                </>
              )}
              onSelect={(e) => {
                setMaterialName(e);
              }}
            >
              {materials.map((value) => {
                return (
                  <Option key={value["id"]} value={value["name"]}>
                    {value["name"]}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              padding: "0.5rem 0rem",
            }}
            xs={10}
          >
            <Row>
              <Col xs={16}>
                <Select
                  mode="multiple"
                  showSearch
                  className="product_inputs"
                  placeholder="Features"
                  value={featuresName}
                  onChange={(e) => {
                    setFeaturesNames([...e]);
                  }}
                  onSearch={(e) => {
                    dispatch(setfeaturename(e));
                  }}
                  onSelect={(e) => {
                    setFeaturesNames([...featuresName, e]);
                  }}
                >
                  {features.map((value) => {
                    return (
                      <Option key={value["id"]} value={value["name"]}>
                        {value["name"]}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col xs={8}>
                <Typography.Link
                  onClick={(e) => {
                    if (featuretypeName !== "") {
                      var item = features.find((val) => `${val["name"]}`.toLowerCase() === `${featuretypeName}`.toLowerCase());
                      if (item) {
                        openNotificationWithIcon("success", "item already exist");
                      } else {
                        addItem("feature");
                        setFeaturesNames([...featuresName, featuretypeName]);
                      }
                    }
                    
                  }}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <PlusOutlined /> Add item
                </Typography.Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={10}>
            <p className="category-title" style={{ marginBottom: "0px" }}>
              {" "}
              Upload primary product image
            </p>
            <ImgCrop onModalOk={(file) => {
              onimageChange({
                fileList: [file],
                type: "primary",
              });
            }} aspect={2}>
              <Upload
                accept="image/png, image/jpeg,image/webp"
                listType="picture-card"
                fileList={primaryImage}
                onChange={(fileList, newfile) => {
                  // onimageChange({
                  //   fileList: fileList,
                  //   type: "primary",
                  // });
                }}
                onRemove={() => {
                  setprimaryImage([]);
                }}
                onPreview={handlePreview}
              >
                {primaryImage.length < 1 && "+ Upload"}
              </Upload>
            </ImgCrop>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handlemodalCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </Col>
          <Col>
            <p className="category-title">Upload product images</p>
            <ImgCrop aspect={2} onModalOk={(file) => {
              onimageChange({
                fileList: [file],
                type: "",
              })
            }}>
              <Upload
                accept="image/png, image/jpeg, image/webp"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onRemove={(i) => {
                  var index = fileList.findIndex((val) => val.uid === i.uid);
                  if (index !== -1) {
                    fileList.splice(index, 1);
                    setFileList([...fileList]);
                  }
                }}
              >
                {fileList.length < 5 && "+ Upload"}
              </Upload>
            </ImgCrop>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handlemodalCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </Col>
        </Row>
        <Row></Row>
        <br />
        <Row>
          <Col xs={12}>
            <p className="category-title"> Product details</p>
            <TextArea
              rows={4}
              placeholder="Description"
              value={productDesc}
              onChange={(e) => {
                setproductDesc(e.target.value);
              }}
            />
            <br />
            {productDetailsinputs.map((value, index) => {
              return (
                <Row style={{ marginTop: "1rem" }}>
                  <Col xs={10}>
                    <Input
                      onChange={(e) => { }}
                      className="product_inputs"
                      defaultValue={value["name"]}
                      placeholder=""
                    />
                  </Col>
                  <Col xs={10} style={{ marginLeft: "1rem" }}>
                    <Input
                      onChange={(e) => {
                        onchangeInput(e, index, "details");
                      }}
                      className="product_inputs"
                      defaultValue={value["value"]}
                      placeholder=""
                    />
                  </Col>
                  <Col xs={2} style={{ margin: " auto 5px" }}>
                    <Typography.Link
                      onClick={() => {
                        removeformItem("product", index);
                      }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <MinusCircleOutlined />
                    </Typography.Link>
                  </Col>
                </Row>
              );
            })}
            <Typography.Link onClick={addform} style={{ whiteSpace: "nowrap" }}>
              <PlusOutlined /> Add new form
            </Typography.Link>
          </Col>
          <Col xs={12}>
            <p className="category-title" style={{ marginLeft: "2rem" }}>
              {" "}
              Frame and meassurements
            </p>
            {framemeasurementInput.map((value, index) => {
              return (
                <Row justify="center" style={{ marginTop: "1rem" }}>
                  <Col xs={10}>
                    <Input
                      onChange={(e) => { }}
                      className="product_inputs"
                      value={value["name"]}
                    />
                  </Col>
                  <Col xs={10} style={{ marginLeft: "1rem" }}>
                    <InputNumber
                      onChange={(e) => {
                        onchangeInput(e, index, "frame");
                      }}
                      className="product_inputs"
                      defaultValue={value["value"]}
                      placeholder=""
                    />
                  </Col>
                  <Col xs={2} style={{ margin: " auto 5px" }}>
                    <Typography.Link
                      onClick={() => {
                        removeformItem("frame", index);
                      }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <MinusCircleOutlined />
                    </Typography.Link>
                  </Col>
                </Row>
              );
            })}
            <Typography.Link
              onClick={() => {
                addform("frame");
              }}
              style={{ whiteSpace: "nowrap", marginLeft: "2rem" }}
            >
              <PlusOutlined /> Add new form
            </Typography.Link>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12}>
            <p className="category-title"> Specifications</p>
            {specificationInputs.map((value, index) => {
              return (
                <Row style={{ marginTop: "1rem" }}>
                  <Col xs={10}>
                    <Input
                      onChange={(e) => { }}
                      className="product_inputs"
                      defaultValue={value["name"]}
                      placeholder=""
                    />
                  </Col>
                  <Col xs={10} style={{ marginLeft: "1rem" }}>
                    <Input
                      onChange={(e) => {
                        onchangeInput(e, index, "spec");
                      }}
                      className="product_inputs"
                      defaultValue={value["value"]}
                      placeholder=""
                    />
                  </Col>
                  <Col xs={2} style={{ margin: " auto 5px" }}>
                    <Typography.Link
                      onClick={() => {
                        removeformItem("spec", index);
                      }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <MinusCircleOutlined />
                    </Typography.Link>
                  </Col>
                </Row>
              );
            })}
            <Typography.Link
              onClick={() => {
                addform("specs");
              }}
              style={{ whiteSpace: "nowrap" }}
            >
              <PlusOutlined /> Add new form
            </Typography.Link>
          </Col>
        </Row>
      </Spin>
      <Row justify="center">
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "1rem 0",
          }}
          xs={12}
        >
          {" "}
          <Button
            onClick={() => {
              var validation = false;
              if (
                productName !== undefined &&
                subtitle !== "" &&
                modalnumber !== "" &&
                brandName !== undefined &&
                gender !== undefined &&
                frameshapeName !== undefined &&
                faceShapename !== undefined &&
                productPrice !== "" &&
                wholesaleprice !== "" &&
                sellingprice !== "" &&
                lensTypesNames.length !== 0 &&
                materialName !== undefined &&
                primaryImage.length !== 0 &&
                fileList.length !== 0 &&
                quantity.length !== 0 &&
                productDesc !== ""
              ) {
                for (let i = 0; i < quantity.length; i++) {
                  const val = quantity[i];
                  if (typeof(val["color"]) !== "number") {
                    var colorID =  colors.find((el)=> `${el["name"]}` === `${val["color"]}`)["id"];
                    quantity[i]["color"] = colorID;
                  }
                  if (val["color"] && val["size"] && val["quantity"] !== "") {
                    validation = true;
                  }
                }
              }
              if (validation) {
                var brandID =  brands.find((val)=> `${val["name"]}` === `${brandName}`)["id"];
                var framshapeID =  frameShapes.find((val)=> `${val["name"]}` === `${frameshapeName}`)["id"];
                var materialID =  materials.find((val)=> `${val["name"]}` === `${materialName}`)["id"];
                var lenstypeIDs = [];
                lensTypesNames.forEach((value)=>{
                  var name =  lensTypes.find((val)=> `${val["name"]}` === `${value}`)["id"];
                  lenstypeIDs.push(name);
                })
                var featureIDs = [];
                featuresName.forEach((value)=>{
                  var name =  features.find((val)=> `${val["name"]}` === `${value}`)["id"];
                  featureIDs.push(name);
                })
                const { state } = props.location;
                var body = {
                  product_name: productName,
                  subtitle: subtitle,
                  modal_name: modalname,
                  modal_number: modalnumber,
                  brand: brandID,
                  gender: gender,
                  kids_category: kidscategory,
                  frame_shape: framshapeID,
                  face_shape: faceShapename,
                  quantity: quantity,
                  product_price: productPrice,
                  wholesale_price: wholesaleprice,
                  selling_price: sellingprice,
                  offer_price: offerprice > 0 ? `${offerprice}` : "0",
                  lens_types: lenstypeIDs,
                  material: materialID,
                  features: featureIDs,
                  primary_image: primaryImage[0]["url"],
                  selected_images: fileList.map((value) => value["url"]),
                  frame_measurement: framemeasurementInput,
                  product_details: productDetailsinputs,
                  description: productDesc,
                  specification: specificationInputs,
                };
                if (spinning === false) {
                  setspinning(true);
                  if (state !== undefined) {
                    body["id"] = state.productID;
                    httpPost("products/update", body).then((res) => {
                      if (res["status"] === "SUCCESS") {
                        setspinning(false);
                        openNotificationWithIcon(
                          "success",
                          "Product updated successfully"
                        );
                        history.push("/tables");
                      }
                    });
                  } else {
                    httpPost("products/add", body).then((res) => {
                      if (res["status"] === "SUCCESS") {
                        setspinning(false);
                        openNotificationWithIcon(
                          "success",
                          "Product updated successfully"
                        );
                        history.push("/tables");
                      }
                    });
                  }
                }
              }
            }}
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Col>
      </Row>
      <Row justify="center">
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "1rem 0",
          }}
          xs={12}
        >
          <Button
            onClick={() => {
              setshowdiscardmodal(true);
            }}
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </StyledAddProduct>
  );
};

export default AddProduct;
