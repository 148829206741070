import { Button, Card, Col, Row, Select } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import Moment from "react-moment";
import { useHistory } from "react-router";
import { httpGet, httpPost } from "../app/networkUtils/networkUtils";

const ViewOrder = (props) => {
  useEffect(() => {
    getorderData(props.location.state.id);
  }, []);
  const [orderData, setorderData] = useState({});

  const history = useHistory();
  const { Option } = Select;
  const getorderData = (orderID) => {
    httpGet(`orders/getdetails?orderID=${orderID}`).then((res) => {
      if (res["status"] === "SUCCESS") {
        setorderData(res["data"]);
      }
    });
  };

  return (
    <>
      <div>
        <Button
          onClick={() => {
            history.goBack();
          }}
        >
          Go Back
        </Button>
       <Card>
       <Row justify="center">
          <Col xs={4}>
            <img src={orderData["primary_image"]} height={"90px"} />
          </Col>
          <Col xs={10}>
          <p>Order id: <b>#{orderData["order_id"]}</b></p>
            <table class="table">
              <tbody>
                <tr>
                  <td>Product Name</td>
                  <td><b>{orderData["name"]}</b></td>
                </tr>
                <tr>
                  <td>Sub title</td>
                  <td><b>{orderData["sub_title"]}</b></td>
                </tr>
                {/* <tr>
                  <td>Product price</td>
                  <td><b>{orderData["product_price"]}</b></td>
                </tr> */}
                <tr>
                  <td>Order price</td>
                  <td><b>AED {orderData["order_price"]}</b></td>
                </tr>
                <tr>
                  <td>Quantity</td>
                  <td><b>{orderData["quantity"]}</b></td>
                </tr>
                <tr>
                  <td>Order Status</td>
                  <td>
                    {Object.keys(orderData).length !== 0 ? (
                      <Select
                        defaultValue={
                          orderData["cancel"] === 1
                            ? orderData["cancel_status"]
                            : orderData["status"]
                        }
                        className={"status_dropdown"}
                        style={{ width: "100%" }}
                        onChange={(val) => {
                          const body = {
                            status: val,
                            orderID: props.location.state.id,
                          };
                          httpPost("orders/updatestatus", body).then((res) => {
                            if (val["status"] === "SUCCESS") {
                            }
                          });
                        }}
                      >
                        {orderData["cancel"] === 1 ? (
                          <>
                            <Option value="order_cancelled">
                              Order Cancelled
                            </Option>
                            <Option value="refund_initiated">
                              Refund initiated
                            </Option>
                            <Option value="returned">Returned</Option>
                            <Option value="refunded">Refunded</Option>
                          </>
                        ) : (
                          <>
                            <Option value="order_placed">Order Placed</Option>
                            <Option value="order_processing">
                              Order processing
                            </Option>
                            <Option value="ready_for_collection">
                              {" "}
                              Ready for collection
                            </Option>
                            <Option value="order_placed_shipment">
                              Order placed to shipment
                            </Option>
                            <Option value="shipping">Shipping</Option>
                            <Option value="order_completed">
                              Order Completed
                            </Option>
                          </>
                        )}
                      </Select>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>Order at</td>
                  <td>
                   <b>
                   <Moment format="YYYY/MM/DD hh:mm a">
                      {orderData["ordered_at"]}
                    </Moment>
                   </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col xs = {10}>
          <p> <b>Customer details</b></p>
          <table class="table">
              <tbody>
                <tr>
                  <td>Customer Name</td>
                  <td><b>{orderData["customer_name"]}</b></td>
                </tr>
                <tr>
                  <td>Mobile Number</td>
                  <td><b>{orderData["mobile_no"]}</b></td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td><b>{orderData["email"]}</b></td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td><b>{orderData["address1"]}</b></td>
                </tr>
                {/* <tr>
                  <td>Product price</td>
                  <td><b>{orderData["product_price"]}</b></td>
                </tr> */}
                <tr>
                  <td>City</td>
                  <td><b> {orderData["city"]}</b></td>
                </tr>
                <tr>
                  <td>State</td>
                  <td><b>{orderData["state"]}</b></td>
                </tr>
               
                <tr>
                  <td>Country</td>
                  <td><b>{orderData["country"]}</b></td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
       </Card>
      </div>
    </>
  );
};

export default ViewOrder;
