import { Form, Input, Button, notification, Row, Col } from "antd";
import { useHistory } from "react-router";
import { httpPost } from "../app/networkUtils/networkUtils";

const CancelOrder = (props) => {
  const history = useHistory();
  const onFinish = (values) => {
    console.log("Success:", values);
    cancel(props.location.state.id, values["Message"]).then((res) => {
      if (res["status"] === "SUCCESS") {
        openNotificationWithIcon();
      }
    });
  };
  const openNotificationWithIcon = () => {
    notification.success({
      message: "SUCCESS",
      description: "Order cancellled",
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const cancel = (orderID, msg) => {
    return new Promise((resolve, reject) => {
      const body = {
        orderID: orderID,
        msg: msg,
      };
      httpPost(`orders/cancelorder`, body).then((res) => {
        resolve(res);
      });
    });
  };
  return (
    <>
      <Button
        onClick={() => {
          history.goBack();
        }}
      >
        Go Back
      </Button>
      <Row justify="center">
        <Col xs={12}>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label=""
              name="Message"
              rules={[
                {
                  required: true,
                  message: "Please type message",
                },
              ]}
            >
              <Input placeholder={"Enter message"} />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Cancel order
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default CancelOrder;
