import {
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  DatePicker,
  Tabs,
  Dropdown,
  Menu,
} from "antd";
import { useEffect, useState } from "react";
import { httpGet, httpPost } from "../app/networkUtils/networkUtils";
import Moment from "react-moment";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Column from "antd/lib/table/Column";
import { useDispatch } from "react-redux";
import {
  setquickviewdata,
  setshippingaddress,
  setshowquickview,
  setshowshippingaddress,
} from "../app/features/counter";
import QuickView from "./Quickview";
import ShippingaddressModal from "./ShippingAddress";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const Orders = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const items = 20;
  const [currentPage, setcurrentPage] = useState(1);
  const [totalItem, settotalItems] = useState();
  const [searchData, setsearchdata] = useState([]);
  const [orders, setOrders] = useState([]);
  const [productData, setproductsData] = useState([]);
  const [selectedrows, setselectedrows] = useState([]);

  const { Option, OptGroup } = Select;
  const { TabPane } = Tabs;

  const getorders = (qs) => {
    return new Promise((resolve, reject) => {
      httpGet(`orders/get?limit=10&offset=${currentPage - 1}${qs ?? ""}`)
        .then((res) => {
          resolve(res);
        })
        .catch((Err) => {
          reject(Err);
        });
    });
  };

  useEffect(() => {
    getorders().then((res) => {
      if (res["status"] === "SUCCESS") {
        setordersdata(res["data"]["orders"]);
        settotalItems(res["data"]["total"]);
      }
    });
  }, []);
  const rowSelection = {
    selectedrows,
    onChange: (row) => {
      setselectedrows([...row]);
    },
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };
  const setordersdata = (data) => {
    var ordrs = [];
    for (let i = 0; i < data.length; i++) {
      const val = data[i];
      ordrs.push({
        ...val,
        key: val["order_id"],
      });
    }
    setOrders([...ordrs]);
    setsearchdata([...ordrs]);
  };
  return (
    <>
      <ShippingaddressModal />
      <div className="tabled">
        <Row>
          <Breadcrumb>
            <BreadcrumbItem>
              <a
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                Home
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>Orders</BreadcrumbItem>
          </Breadcrumb>
        </Row>
        <br />
        <Row justify="center">
          <Col xs={16}>
            <Row
              style={{
                backgroundColor: "#333",
                borderRadius: "13px",
              }}
            >
              <Col xs={6}>
                <Dropdown
                  overlay={() => {
                    return (
                      <Menu
                        onChange={(e) => {
                          console.log(e);
                        }}
                      >
                        <Menu.Item key="0" onClick={() => { }}>
                          Today
                        </Menu.Item>
                        <Menu.Item key="1" onClick={() => { }}>
                          Last 7 days
                        </Menu.Item>
                        <Menu.Item key="1" onClick={() => { }}>
                          Last 30 days
                        </Menu.Item>
                      </Menu>
                    );
                  }}
                  trigger={["click"]}
                >
                  <Button
                    style={{
                      marginLeft: "9px",
                      width: "100%",
                      color: "#fff",
                      backgroundColor: "#333",
                      textAlign: "center",
                      border: "0",
                      fontSize: "16px",
                    }}
                    className="border-raduis-right-0 border-raduis-left-0 "
                    onClick={(e) => e.preventDefault()}
                  >
                    Today <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
              <Col
                style={{ borderLeft: "1px solid #fff", padding: "3px" }}
                xs={6}
              >
                <div
                  style={{
                    width: "100%",
                    color: "#fff",
                    backgroundColor: "#333",
                    textAlign: "center",
                  }}
                  onClick={(e) => e.preventDefault()}
                >
                  Ordered Items
                  <p>1</p>
                </div>
              </Col>
              <Col
                style={{ borderLeft: "1px solid #fff", padding: "3px" }}
                xs={6}
              >
                <div
                  style={{
                    width: "100%",
                    color: "#fff",
                    backgroundColor: "#333",
                    textAlign: "center",
                  }}
                  onClick={(e) => e.preventDefault()}
                >
                  Paid Items
                  <p>1</p>
                </div>
              </Col>
              <Col
                style={{ borderLeft: "1px solid #fff", padding: "3px" }}
                xs={6}
              >
                <div
                  style={{
                    width: "100%",
                    color: "#fff",
                    backgroundColor: "#333",
                    textAlign: "center",
                  }}
                  onClick={(e) => e.preventDefault()}
                >
                  Unpaid Items
                  <p>0</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Card bordered={false}>
          <Row justify="end">
            <Col xs={12} style={{ textAlign: "right" }}>
              <Button type="primary" onClick={() => history.push("/product")}>
                Add products
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <h5 style={{ color: "#1890ff" }}>{totalItem} Orders</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Tabs
                defaultActiveKey="1"
                onChange={(e) => {
                  let qs = "";
                  if (e === "2") {
                    qs = "&active=1";
                  } else if (e === "3") {
                    qs = "&active=0";
                  } else if (e === "4") {
                    qs = "&sold=1";
                  } else if (e === "5") {
                    qs = "&archive=1";
                  }
                }}
              >
                <TabPane tab="All" key="1"></TabPane>
                <TabPane tab="Paid" key="2"></TabPane>
                <TabPane tab="Unpaid" key="3"></TabPane>
                <TabPane tab="Archive" key="4"></TabPane>
              </Tabs>
            </Col>
          </Row>
          <Row style={{ marginTop: "5px" }}>
            <Col xs={6}>
              <Input
                size="small"
                placeholder="Search products..."
                className="search_product"
                prefix={<SearchOutlined />}
                width="100%"
                onChange={(e) => {
                  var items = searchData.filter((val) => {
                    return (
                      val["product_name"]
                        .toLowerCase()
                        .startsWith(e.target.value.toLowerCase()) ||
                      val["user_name"]
                        .toLowerCase()
                        .startsWith(e.target.value.toLowerCase()) ||
                      val["user_number"]
                        .toLowerCase()
                        .startsWith(e.target.value.toLowerCase())
                    );
                  });
                  setOrders([...items]);
                }}
              />
            </Col>
            <Col xs={6}>
              <Space direction="vertical" size={12}>
                <RangePicker
                  style={{
                    marginLeft: "5px",
                    height: "40px",
                    marginRight: "5px",
                  }}
                  onChange={(startDate, endDate) => {
                    if (endDate) {
                      let qs = "";
                      if (endDate[0] != "") {
                        qs = `&start_at=${endDate[0]}&end_at=${endDate[1]}`;
                      }
                      getorders(qs).then((res) => {
                        if (res["status"] === "SUCCESS") {
                          setordersdata(res["data"]["orders"]);
                          settotalItems(res["data"]["total"]);
                        }
                      });
                    }
                  }}
                />
              </Space>
            </Col>
            <Col xs={3}>
              <Dropdown
                overlay={() => {
                  return (
                    <Menu
                      onChange={(e) => {
                        console.log(e);
                      }}
                    >
                      <Menu.Item key="0" onClick={() => { }}>
                        Order Received
                      </Menu.Item>
                      <Menu.Item key="1" onClick={() => { }}>
                        Order processing
                      </Menu.Item>
                      <Menu.Item key="2" onClick={() => { }}>
                        Cancelled by vendor
                      </Menu.Item>
                      <Menu.Item key="3" onClick={() => { }}>
                        Cancelled by client
                      </Menu.Item>

                      <Menu.Item key="4" onClick={() => { }}>
                        Ready for collection
                      </Menu.Item>
                      <Menu.Item key="5" onClick={() => { }}>
                        Order placed for shipment
                      </Menu.Item>
                      <Menu.Item key="6" onClick={() => { }}>
                        Shipping
                      </Menu.Item>
                      <Menu.Item key="7" onClick={() => { }}>
                        Delivered
                      </Menu.Item>
                      <Menu.Item key="8" onClick={() => { }}>
                        Order Completed
                      </Menu.Item>
                      <Menu.Item key="9" onClick={() => { }}>
                        Returned
                      </Menu.Item>
                      <Menu.Item key="10" onClick={() => { }}>
                        Refunded
                      </Menu.Item>

                    </Menu>
                  );
                }}
                trigger={["click"]}
              >
                <Button
                  style={{ width: "100%" }}
                  className="border-raduis-right-0"
                  onClick={(e) => e.preventDefault()}
                >
                  STATUS <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
            <Col xs={3}>
              <Dropdown
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item key="0" onClick={() => { }}>
                        Product title A-Z
                      </Menu.Item>
                      <Menu.Item key="1" onClick={() => { }}>
                        Product title Z-A
                      </Menu.Item>
                      <Menu.Item key="2" onClick={() => { }}>
                        Created (oldest first)
                      </Menu.Item>
                      <Menu.Item key="3" onClick={() => { }}>
                        Created (newest first)
                      </Menu.Item>
                      <Menu.Item
                        key="4"
                        onClick={() => {
                          getorders("&order_cancel_vendor=1").then((res) => {
                            if (res["status"] === "SUCCESS") {
                              setordersdata(res["data"]["orders"]);
                              settotalItems(res["data"]["total"]);
                            }
                          });
                        }}
                      >
                        Cancelled by vendor
                      </Menu.Item>
                      <Menu.Item
                        key="5"
                        onClick={() => {
                          getorders("&order_cancel_client=1").then((res) => {
                            if (res["status"] === "SUCCESS") {
                              setordersdata(res["data"]["orders"]);
                              settotalItems(res["data"]["total"]);
                            }
                          });
                        }}
                      >
                        Cancelled by client
                      </Menu.Item>
                    </Menu>
                  );
                }}
                trigger={["click"]}
              >
                <Button
                  style={{ width: "100%" }}
                  className="border-raduis-left-0 border-left-0"
                  onClick={(e) => e.preventDefault()}
                >
                  SORT <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
            {/* <Col xs={6}>
                <Select
                  placeholder="Select filter"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    let qs = "";
                    if (e === "cancel_vendor") {
                      qs = "&order_cancel_vendor=1";
                    } else if (e === "cancel_client") {
                      qs = "&order_cancel_client=1";
                    } else {
                      qs = `&order_status=${e}`;
                    }
                    getorders(qs).then((res) => {
                      if (res["status"] === "SUCCESS") {
                        setordersdata(res["data"]["orders"]);
                        settotalItems(res["data"]["total"]);
                      }
                    });
                  }}
                >
                  <OptGroup label="Order status">
                    <Option value="order_placed">Order Placed</Option>
                    <Option value="order_processing">Order processing</Option>
                  </OptGroup>
                  <OptGroup label="Cancelled">
                    <Option value="cancel_vendor">Cancelled by vendor</Option>
                    <Option value="cancel_client">Cancelled by client</Option>
                  </OptGroup>{" "}
                </Select>
              </Col> */}
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <br />
              <Row style={{ marginTop: "5px" }}>
                <Col>
                  {selectedrows.length !== 0 ? (
                    <>
                      <Button>{selectedrows.length} selected</Button>
                      <Dropdown
                        trigger={["click"]}
                        overlay={() => {
                          return (
                            <Menu>
                               <Menu.Item
                                key="1"
                                onClick={() => {
                                }}
                              >
                                Order Received
                              </Menu.Item>
                              <Menu.Item
                                key="2"
                                onClick={() => {
                                }}
                              >
                                Order processing
                              </Menu.Item>
                              <Menu.Item
                                key="3"
                                onClick={() => {
                                }}
                              >
                                Ready for collection
                              </Menu.Item>
                              <Menu.Item
                                key="4"
                                onClick={() => {
                                }}
                              >
                                Order placed for shipment
                              </Menu.Item>
                              <Menu.Item key="6" onClick={() => { }}>
                                Shipping
                              </Menu.Item>
                              <Menu.Item key="7" onClick={() => { }}>
                                Delivered
                              </Menu.Item>
                              <Menu.Item key="8" onClick={() => { }}>
                                Order Completed
                              </Menu.Item>
                              <Menu.Item key="9" onClick={() => { }}>
                                Returned
                              </Menu.Item>
                              <Menu.Item key="10" onClick={() => { }}>
                                Refunded
                              </Menu.Item>
                              <Menu.Item key="10" onClick={() => { }}>
                                Archive order
                              </Menu.Item>
                              <Menu.Item
                                key="5"
                                onClick={() => {
                                  history.push(`/printorder?id=${selectedrows[0]}`)
                                }}
                              >
                                Print order
                              </Menu.Item>

                            </Menu>
                          );
                        }}
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <Button>
                            More Action <DownOutlined />
                          </Button>
                        </a>
                      </Dropdown>
                    </>
                  ) : null}
                </Col>
              </Row>
              <div className="table-responsive">
                <Table
                  dataSource={orders}
                  pagination={false}
                  rowSelection={rowSelection}
                  className="ant-border-space"
                  onRow={(record, i) => {
                    return {
                      onClick: (e) => { },
                    };
                  }}
                >
                  <Column
                    title="#"
                    render={(val, rec, index) => {
                      return <p> {index}</p>;
                    }}
                  ></Column>
                  <Column
                    title="PRODUCT"
                    onCell={(record, i) => {
                      return {
                        onClick: (e) => {
                          history.push({
                            pathname: "/vieworder",
                            state: {
                              id: record.order_id,
                            },
                          });
                        },
                      };
                    }}
                    render={(text, value) => (
                      <Avatar.Group style={{ cursor: "pointer" }}>
                        <Avatar
                          className="shape-avatar"
                          shape="circle"
                          size={40}
                          src={value["product_image"]}
                        ></Avatar>
                        <div className="avatar-info">
                          <Title level={5}>{value["product_name"]}</Title>
                          <p>{value["sub_title"]}</p>
                        </div>
                      </Avatar.Group>
                    )}
                  />
                   <Column
                    title="CREATED AT"
                    render={(text, value) => (
                      <div className="ant-employed">
                        <Title level={5}>
                          <Moment format="YYYY/MM/DD hh:mm a">
                            {value["created_at"]}
                          </Moment>{" "}
                        </Title>
                      </div>
                    )}
                    sorter={(a, b) => {
                      return new Date(b.created_at) - new Date(a.created_at);
                    }}
                  />
                   <Column
                    title="USER NAME"
                    render={(text, value) => (
                      <div className="author-info">
                        <Title level={5}>{value["user_name"]}</Title>
                      </div>
                    )}
                  />
                  <Column
                    title="STATUS"
                    render={(text, value) => (
                      <div
                        className={
                          value["status"] === "order_placed"
                            ? "order-status red-bg"
                            : value["status"] === "ordr_processing"
                              ? "order-status orange-bg"
                              : value["status"] === "order_completed"
                                ? "order-status green-bg"
                                : "order-status red-bg"
                        }
                      >
                        {value["status"]}
                      </div>
                    )}
                    width="100%"
                  />
                  <Column
                    title="CANCELLATION"
                    render={(text, value) => (
                      <div className="ant-employed">
                        {value["cancel"] === 1 ? (
                          <Tag color={"red"}> Cancelled by client </Tag>
                        ) : null}
                        {value["vendor_cancel"] === 1 ? (
                          <Tag color={"red"}> Cancelled by vendor </Tag>
                        ) : null}
                      </div>
                    )}
                  />
                 
                  <Column
                    title="Action"
                    key="action"
                    render={(text, record) => (
                      <Space size="middle">
                        {/* <a
                          onClick={() => {
                            httpGet(
                              `products/quickview?id=${record.product_id}`
                            ).then((res) => {
                              console.log(res);
                              if (res["status"] === "SUCCESS") {
                                dispatch(setquickviewdata(res["data"]));
                                dispatch(setshowquickview(true));
                              }
                            });
                          }}
                        >
                          Quick View
                        </a> */}
                        <a
                          style={{ color: "#1890ff" }}
                          onClick={() => {
                            httpGet(
                              `orders/getshippingaddress?userID=${record.user_id}`
                            ).then((res) => {
                              if (res["status"] === "SUCCESS") {
                                dispatch(setshippingaddress(res["data"]));
                                dispatch(setshowshippingaddress(true));
                              }
                            });
                          }}
                        >
                          View Shipping Address
                        </a>
                        <a
                          onClick={() => {
                            history.push({
                              pathname: "/cancelorder",
                              state: {
                                id: record.order_id,
                              },
                            });
                          }}
                        >
                          Cancel order
                        </a>
                      </Space>
                    )}
                  />
                </Table>
              </div>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col xs={12}>
            <Pagination
              current={currentPage}
              defaultPageSize={items}
              defaultCurrent={currentPage}
              total={totalItem}
              onChange={(value) => {
                setcurrentPage(value - 1);
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Orders;
