import styled from "styled-components";

const StyledHomeEdit = styled.div`
  .title {
    display: flex;
    justify-content: center;

    .title_category {
      display: flex;
      justify-content: center;
    }
  }
`;

export default StyledHomeEdit;
