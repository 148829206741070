import styled from "styled-components";

const StyledAddProduct = styled.div`
  .product_inputs {
    width: 100%;
    max-width: 400px;
  }
  .main-title {
    color: #404040;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  .category-title {
    font-size: 16px;
    color: #404040;
    font-weight: 500;
  }
`;

export default StyledAddProduct;
