import { auth } from "../../firebase/config";

// const baseUrl = "http://localhost:8000/v1/vendor/";
const baseUrl = "https://api.kareye.com/v1/vendor/";

export async function httpGet(api) {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(
      (user) => {
        fetch(baseUrl + api, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.uid}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.json());
            } else {
              reject("Error");
            }
          })
          .catch(reject);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export async function httpPost(api, body) {
  const token = auth.currentUser.uid;
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(
      (user) => {
        fetch(baseUrl + api, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.uid}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.json());
            } else {
              reject("Error");
            }
          })
          .catch(reject);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
