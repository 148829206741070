/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix, Menu } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import { DesktopOutlined, FileOutlined, HeartOutlined, LoginOutlined, LogoutOutlined, PieChartOutlined, SettingOutlined, ShoppingCartOutlined, TableOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import SubMenu from "antd/lib/menu/SubMenu";
import { NavLink } from "react-router-dom";
import { auth } from "../../firebase/config";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  let { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const [collapsed, setcollapse] = useState(false);
  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);
  const onCollapse = () => {
    setcollapse(!collapsed);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div className="logo" />
        <Menu theme="dark"  mode="inline">
          <Menu.Item key="2" icon={<DesktopOutlined />}>
            <NavLink to="/dashboard">
              <span
                className="icon"
                style={{
                  background: pathname === "dashboard" ? sidenavColor : "",
                }}
              >
              </span>
              <span className="label">Dashboard</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="3" icon={<TableOutlined />}>
            <NavLink to="/tables">
              <span
                className="icon"
                style={{
                  background: page === "tables" ? sidenavColor : "",
                }}
              >
              </span>
              <span className="label">Products</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4" icon={<ShoppingCartOutlined />}>
            <NavLink to="/orders">
              <span
                className="icon"
                style={{
                  background: page === "orders" ? sidenavColor : "",
                }}
              >
              </span>
              <span className="label">Orders</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="9" icon={<ShoppingCartOutlined />}>
            <NavLink to="/abondendcheckout">
              <span
                className="icon"
                style={{
                  background: page === "abandoned" ? sidenavColor : "",
                }}
              >
              </span>
              <span className="label">Abandoned checkout</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="5" icon={<HeartOutlined />}>
            <NavLink to="/wishlist">
              <span
                className="icon"
                style={{
                  background: page === "wishlist" ? sidenavColor : "",
                }}
              >
              </span>
              <span className="label">WishList</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item className="menu-item-header" key="6">
            Others
          </Menu.Item>
          <Menu.Item key="7" icon={<SettingOutlined />}>
            <NavLink to="/">
              <span
                className="icon"
                style={{
                  background: page === "wishlist" ? sidenavColor : "",
                }}
              >
              </span>
              <span className="label">Settings</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="8" icon={<LogoutOutlined />}>
            <NavLink to="/" onClick={async () => {
              await auth.signOut();
            }}>
              <span
                className="icon"
                style={{
                  background: page === "" ? sidenavColor : "",
                }}
              >
              </span>
              <span className="label">Logout</span>
            </NavLink>
          </Menu.Item>
          {/* <Menu.Item key="5" icon ={HeartOutlined}>
          <NavLink to="/wishlist">
            <span
              className="icon"
              style={{
                background: page === "wishlist" ? sidenavColor : "",
              }}
            >
            </span>
            <span className="label">WishList</span>
          </NavLink>
        </Menu.Item> */}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }} />
        <Content style={{ margin: '0 16px' }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}

export default Main;
