import React, { useState } from "react";
import StyledHomeEdit from "./StyledHomeEdit";
import { Row, Col, Divider } from "antd";
import { Form, Input, Button, Space, Select, Upload, message } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import StyledFiledUploadButton from "../components/layout/StyledFiledUploadButton";

const HomeEdit = () => {
  const [Toggle, setToggle] = useState(false);
  const onFinish = (values) => {
    console.log("Received values of form:", values);
  };

  return (
    // <StyledHomeEdit>
    //   <Row justify="center">
    //     <Col style={{ padding: "1rem  0" }}>Home edit Form</Col>
    //   </Row>

    //   <Form
    //     initialValues={{
    //       remember: true,
    //     }}
    //     onFinish={onFinish}
    //     autoComplete="off"
    //   >
    //     <Row style={{ display: "flex", justifyContent: "center" }}>
    //       <Col xs={24}>
    //         {" "}
    //         <Form.List name="slider">
    //           {(fields, { add, remove }) => (
    //             <>
    //               {fields.map(({ key, name, ...restField }) => (
    //                 <Space
    //                   key={key}
    //                   style={{ display: "flex", marginBottom: 8 }}
    //                   align="baseline"
    //                 >
    //                   <Form.Item
    //                     {...restField}
    //                     name={[name, "first"]}
    //                     rules={[
    //                       { required: true, message: "Missing first name" },
    //                     ]}
    //                   >
    //                     <Upload
    //                       onChange={(info) => (info.file.status = "done")}
    //                       listType="picture"
    //                       maxCount={1}
    //                     >
    //                       <Button icon={<UploadOutlined />}>
    //                         Upload (Max: 1)
    //                       </Button>
    //                     </Upload>
    //                   </Form.Item>
    //                   <Form.Item
    //                     {...restField}
    //                     name={[name, "last"]}
    //                     rules={[
    //                       { required: true, message: "Missing last name" },
    //                     ]}
    //                   >
    //                     <Input placeholder="Last Name" />
    //                   </Form.Item>
    //                   <MinusCircleOutlined onClick={() => remove(name)} />
    //                 </Space>
    //               ))}
    //               <Form.Item>
    //                 <Button
    //                   type="dashed"
    //                   onClick={() => add()}
    //                   block
    //                   icon={<PlusOutlined />}
    //                 >
    //                   Add slider images
    //                 </Button>
    //               </Form.Item>
    //             </>
    //           )}
    //         </Form.List>
    //       </Col>

    //       <Col
    //         style={{ display: "flex", justifyContent: "center" }}
    //         xs={24}
    //         style={{ padding: "0 4rem" }}
    //       >
    //         {" "}
    //         <Form.List name="brand">
    //           {(fields, { add, remove }) => (
    //             <>
    //               {fields.map(({ key, name, ...restField }) => (
    //                 <Space
    //                   key={key}
    //                   style={{ display: "flex", marginBottom: 8 }}
    //                   align="baseline"
    //                 >
    //                   <Form.Item
    //                     {...restField}
    //                     name={[name, "first"]}
    //                     rules={[
    //                       { required: true, message: "Missing first name" },
    //                     ]}
    //                   >
    //                     <Upload
    //                       onChange={(info) => (info.file.status = "done")}
    //                       listType="picture"
    //                       maxCount={1}
    //                     >
    //                       <Button icon={<UploadOutlined />}>
    //                         Upload (Max: 1)
    //                       </Button>
    //                     </Upload>
    //                   </Form.Item>
    //                   <Form.Item
    //                     {...restField}
    //                     name={[name, "last"]}
    //                     rules={[
    //                       { required: true, message: "Missing last name" },
    //                     ]}
    //                   >
    //                     <Input placeholder="Last Name" />
    //                   </Form.Item>
    //                   <MinusCircleOutlined onClick={() => remove(name)} />
    //                 </Space>
    //               ))}

    //               <Form.Item>
    //                 <Button
    //                   type="dashed"
    //                   onClick={() => add()}
    //                   block
    //                   icon={<PlusOutlined />}
    //                   style={{ padding: "0 4rem" }}
    //                 >
    //                   Add brand images
    //                 </Button>
    //               </Form.Item>
    //             </>
    //           )}
    //         </Form.List>
    //       </Col>
    //     </Row>

    //     <Row style={{ display: "flex", justifyContent: "center" }}>
    //       <Col
    //         style={{ display: "flex", justifyContent: "center" }}
    //         xs={24}
    //         style={{ padding: "0 4rem" }}
    //       >
    //         <Upload
    //           onChange={(info) => (info.file.status = "done")}
    //           listType="picture"
    //           maxCount={5}
    //         >
    //           <Button icon={<UploadOutlined />}>deal of the month</Button>
    //         </Upload>
    //       </Col>

    //       <Col
    //         style={{ display: "flex", justifyContent: "center" }}
    //         xs={24}
    //         style={{ padding: "0 4rem" }}
    //       >
    //         {" "}
    //         <Form.Item>
    //           <Upload listType="picture" maxCount={3} multiple>
    //             <Button icon={<UploadOutlined />}>Category Images</Button>
    //           </Upload>
    //         </Form.Item>
    //       </Col>
    //     </Row>

    //     <Col style={{ display: "flex", justifyContent: "center" }} xs={24}>
    //       <Form.Item style={{ marginLeft: "30%" }} className="edit_form_button">
    //         <Button
    //           type="primary"
    //           htmlType="submit"
    //           style={{ padding: "0 4rem" }}
    //         >
    //           Submit
    //         </Button>
    //       </Form.Item>
    //     </Col>
    //   </Form>
    // </StyledHomeEdit>
    <>
      <StyledHomeEdit>
        <div className="title">
          <h1> EDIT YOUR HOMEPAGE</h1>
        </div>
        <Divider style={{ margin: "0", backgroundColor: "#D3D3D3" }} />

        <Row>
          <Col xs={24}>
            {" "}
            <h1 style={{ marginTop: "1rem" }}>HOME EDITOR </h1>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            xs={24}
          >
            {" "}
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "1.5rem 0",
                }}
              >
                <h1>HOME SLIDER IMAGES</h1>
              </div>

              <Form.List name="slider">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 0 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "first"]}
                          rules={[
                            { required: true, message: "Missing first name" },
                          ]}
                        >
                          <Upload
                            onChange={(info) => (info.file.status = "done")}
                            listType="picture"
                            maxCount={1}
                          >
                            <Button
                              style={{ padding: "0 3rem" }}
                              icon={<UploadOutlined />}
                            >
                              IMAGES
                            </Button>
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "last"]}
                          rules={[
                            { required: true, message: "Missing last name" },
                          ]}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        style={{ padding: "0 4rem" }}
                        icon={<PlusOutlined />}
                      >
                        Add IMAGES
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Col xs={24}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "1.5rem 0",
                  }}
                >
                  <h1>HOME BRAND IMAGES</h1>
                </div>
                <Form.List name="brand">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            key="1"
                            {...restField}
                            name={[name, "first"]}
                            rules={[
                              { required: true, message: "Missing first name" },
                            ]}
                          >
                            <Upload
                              onChange={(info) => (info.file.status = "done")}
                              listType="picture"
                              maxCount={1}
                            >
                              <Button
                                style={{ padding: "0 3rem" }}
                                icon={<UploadOutlined />}
                              >
                                IMAGES
                              </Button>
                            </Upload>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "last"]}
                            rules={[
                              { required: true, message: "Missing last name" },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          style={{ padding: "0 4rem" }}
                          icon={<PlusOutlined />}
                        >
                          Add IMAGES
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
              <Col xs={24}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "1.5rem 0",
                  }}
                >
                  <h1>DEAL OF THE MONTH </h1>
                </div>
                <Form.Item>
                  <Upload listType="picture" maxCount={5} multiple>
                    <Button
                      style={{ padding: "0 3rem" }}
                      icon={<UploadOutlined />}
                    >
                      Deal OF THE MONTH
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "1.5rem 0",
                  }}
                >
                  <h1>Category Images</h1>
                </div>
                <Form.Item>
                  <Upload listType="picture" maxCount={3} multiple>
                    <Button
                      style={{ padding: "0 3rem" }}
                      icon={<UploadOutlined />}
                    >
                      CATEGORY IMAGES
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </StyledHomeEdit>
    </>
  );
};

export default HomeEdit;
