import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Pagination,
  Breadcrumb,
  Input,
  Space,
  DatePicker,
  Select,
  Dropdown,
  Menu,
  notification,
  Tabs,
  Modal,
  Checkbox,
} from "antd";

import { useHistory } from "react-router-dom";
import { httpGet, httpPost } from "../app/networkUtils/networkUtils";
import { useEffect, useState } from "react";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import {
  ArrowDownOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { useDispatch } from "react-redux";
import { setquickviewdata, setshowquickview } from "../app/features/counter";
import QuickView from "./Quickview";

const { Title } = Typography;
const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

// table code start
const columns = [
  {
    title: "PRODUCT",
    dataIndex: "product",
    key: "name",
  },
  {
    title: "BRAND",
    dataIndex: "brand",
    key: "function",
  },

  {
    title: "PRODUCT PRICE",
    key: "product_price",
    dataIndex: "product_price",
  },
  {
    title: "SELLING PICE",
    key: "selling_price",
    dataIndex: "selling_price",
  },
  {
    title: "QUANTITY",
    key: "quantity",
    dataIndex: "quantity",
  },
];
function Tables() {
  const items = 20;
  const [searchData, setsearchdata] = useState([]);
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const [currentPage, setcurrentPage] = useState(0);
  const [totalItem, settotalItems] = useState();
  const [selectedrows, setselectedrows] = useState([]);
  const [showcollectionmodal, setshowcollectionmodal] = useState(false);
  const [collectionGlasstypes, setcollectionGlasstypes] = useState([]);
  const dispatch = useDispatch();
  const glasstyeps = [
    "Sun Glass",
    "Eye Glass",
    "Contact lens",
    "Computer Glass",
    "Protective Eyewear",
    "Designer Glass",
  ];
  useEffect(() => {
    getproducts().then((res) => {
      if (res["status"] === "SUCCESS") {
        tabledata(res["data"]["list"]);
        settotalItems(res["data"]["total"]);
      }
    });
  }, []);
  const tabledata = (data) => {
    let list = [];
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      list.push({
        ...element,
        key: element["id"],
      });
    }
    setProducts([...list]);
    setsearchdata([...list]);
  };
  const rowSelection = {
    selectedrows,
    onChange: (row) => {
      setselectedrows([...row]);
    },
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const getproducts = (qs) => {
    return new Promise((resolve, reject) => {
      httpGet(`products/get?limit=${items}&offset=${currentPage}${qs ?? ""}`)
        .then((res) => {
          resolve(res);
        })
        .catch((Err) => {
          reject(Err);
        });
    });
  };

  return (
    <>
      <QuickView />
      <Modal
        title="Add product to collections"
        visible={showcollectionmodal}
        onOk={() => {
          console.log(collectionGlasstypes);
          if (collectionGlasstypes.length !== 0) {
            const body = {
              id: selectedrows.join(","),
              types: collectionGlasstypes.join(","),
            };
            httpPost("products/collection/addglasstypes", body).then((res) => {
              console.log(res);
            });
          }
          setshowcollectionmodal(false);
        }}
        onCancel={() => {
          setshowcollectionmodal(false);
        }}
      >
        <Row>
          <Col xs={12}>
            {glasstyeps.map((val) => {
              return (
                <>
                  <Checkbox
                    value={val}
                    checked={collectionGlasstypes.indexOf(val) !== -1}
                    onChange={(va) => {
                      console.log(va.target.value);
                      const value = va.target.value;
                      var index = collectionGlasstypes.indexOf(value);
                      console.log(index);
                      if (index === -1) {
                        collectionGlasstypes.push(value);
                      } else {
                        collectionGlasstypes.splice(index, 1);
                      }
                      setcollectionGlasstypes([...collectionGlasstypes]);
                    }}
                  >
                    {val}
                  </Checkbox>
                  <br />
                </>
              );
            })}
          </Col>
        </Row>
      </Modal>
      <div className="tabled">
        <Row>
          <Breadcrumb>
            <BreadcrumbItem>
              <a href="/dashboard">Home</a>
            </BreadcrumbItem>
            <BreadcrumbItem>Products</BreadcrumbItem>
          </Breadcrumb>
        </Row>
        <Card>
          <Row justify="end">
            <Col xs={12} style={{ textAlign: "right" }}>
              <Button type="primary" onClick={() => history.push("/product")}>
                Add products
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <h5 style={{ color: "#1890ff" }}>{totalItem} Products</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Tabs
                defaultActiveKey="1"
                onChange={(e) => {
                  let qs = "";
                  if (e === "2") {
                    qs = "&active=1";
                  } else if (e === "3") {
                    qs = "&active=0";
                  } else if (e === "4") {
                    qs = "&sold=1";
                  } else if (e === "5") {
                    qs = "&archive=1";
                  }
                  getproducts(qs).then((res) => {
                    if (res["status"] === "SUCCESS") {
                      tabledata(res["data"]["list"]);
                    }
                  });
                }}
              >
                <TabPane tab="All" key="1"></TabPane>
                <TabPane tab="Active" key="2"></TabPane>
                <TabPane tab="Inactive" key="3"></TabPane>
                <TabPane tab="Sold" key="4"></TabPane>
                <TabPane tab="Archived" key="5"></TabPane>
              </Tabs>
            </Col>
          </Row>
          <Row style={{ marginTop: "5px" }}>
            <Col xs={6}>
              <Input
                size="small"
                placeholder="Search products..."
                className="search_product"
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  var items = searchData.filter((val) => {
                    return (
                      val["product_name"]
                        .toLowerCase()
                        .startsWith(e.target.value.toLowerCase()) ||
                      val["quantity"] == e.target.value ||
                      val["product_price"] == e.target.value ||
                      val["selling_price"] == e.target.value
                    );
                  });
                  setProducts([...items]);
                }}
              />
            </Col>
            <Col xs={6}>
              <Space direction="vertical" size={12}>
                <RangePicker
                  style={{
                    marginLeft: "5px",
                    height: "40px",
                    marginRight: "5px",
                  }}
                  onChange={(startDate, endDate) => {
                    if (endDate) {
                      let qs = "";
                      if (endDate[0] != "") {
                        qs = `&start_at=${endDate[0]}&end_at=${endDate[1]}`;
                      }
                      getproducts(qs).then((res) => {
                        if (res["status"] === "SUCCESS") {
                          tabledata(res["data"]["list"]);
                        }
                      });
                    }
                  }}
                />
              </Space>
            </Col>
            {/* <Col xs={6}>
              <Select
                placeholder="Select filter"
                style={{ width: "100%" }}
                onChange={(e) => {
                  let qs = "";
                  if (e === "active") {
                    qs = "&active=1";
                  } else if (e === "inactive") {
                    qs = "&active=0";
                  }
                  getproducts(qs).then((res) => {
                    if (res["status"] === "SUCCESS") {
                      tabledata(res["data"]["list"]);
                    }
                  });
                }}
              >
                <OptGroup label="Order status">
                  <Option value="active">Active</Option>
                  <Option value="inactive">Inactive</Option>
                </OptGroup>
              </Select>
            </Col> */}
            <Col xs={3}>
              <Dropdown
                overlay={() => {
                  return (
                    <Menu
                      onChange={(e) => {
                        console.log(e);
                      }}
                    >
                      <Menu.Item
                        key="0"
                        onClick={() => {
                          getproducts("&active=1").then((res) => {
                            if (res["status"] === "SUCCESS") {
                              tabledata(res["data"]["list"]);
                            }
                          });
                        }}
                      >
                        Active
                      </Menu.Item>
                      <Menu.Item
                        key="1"
                        onClick={() => {
                          getproducts("&active=0").then((res) => {
                            if (res["status"] === "SUCCESS") {
                              tabledata(res["data"]["list"]);
                            }
                          });
                        }}
                      >
                        Inactive
                      </Menu.Item>
                      <Menu.Item
                        key="2"
                        onClick={() => {
                          getproducts("&sold=1").then((res) => {
                            if (res["status"] === "SUCCESS") {
                              tabledata(res["data"]["list"]);
                            }
                          });
                        }}
                      >
                        Sold
                      </Menu.Item>
                    </Menu>
                  );
                }}
                trigger={["click"]}
              >
                <Button
                  style={{ width: "100%" }}
                  className="border-raduis-right-0"
                  onClick={(e) => e.preventDefault()}
                >
                  STATUS <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
            <Col xs={3}>
              <Dropdown
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item
                        key="0"
                        onClick={() => {
                          getproducts("&order_name=ASC").then((res) => {
                            if (res["status"] === "SUCCESS") {
                              tabledata(res["data"]["list"]);
                            }
                          });
                        }}
                      >
                        Product title A-Z
                      </Menu.Item>
                      <Menu.Item
                        key="1"
                        onClick={() => {
                          getproducts("&order_name=DESC").then((res) => {
                            if (res["status"] === "SUCCESS") {
                              tabledata(res["data"]["list"]);
                            }
                          });
                        }}
                      >
                        Product title Z-A
                      </Menu.Item>
                      <Menu.Item
                        key="2"
                        onClick={() => {
                          getproducts("&order_date=ASC").then((res) => {
                            if (res["status"] === "SUCCESS") {
                              tabledata(res["data"]["list"]);
                            }
                          });
                        }}
                      >
                        Created (oldest first)
                      </Menu.Item>
                      <Menu.Item
                        key="3"
                        onClick={() => {
                          getproducts("&order_date=DESC").then((res) => {
                            if (res["status"] === "SUCCESS") {
                              tabledata(res["data"]["list"]);
                            }
                          });
                        }}
                      >
                        Created (newest first)
                      </Menu.Item>
                      <Menu.Item
                        key="4"
                        onClick={() => {
                          getproducts("&order_quantity=ASC").then((res) => {
                            if (res["status"] === "SUCCESS") {
                              tabledata(res["data"]["list"]);
                            }
                          });
                        }}
                      >
                        Quantity (highest first)
                      </Menu.Item>
                      <Menu.Item
                        key="5"
                        onClick={() => {
                          getproducts("&order_quantity=DESC").then((res) => {
                            if (res["status"] === "SUCCESS") {
                              tabledata(res["data"]["list"]);
                            }
                          });
                        }}
                      >
                        Quantity (lowest first)
                      </Menu.Item>
                    </Menu>
                  );
                }}
                trigger={["click"]}
              >
                <Button
                  style={{ width: "100%" }}
                  className="border-raduis-left-0 border-left-0"
                  onClick={(e) => e.preventDefault()}
                >
                  SORT <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Row style={{ marginTop: "5px" }}>
                <Col>
                  {selectedrows.length !== 0 ? (
                    <>
                      <Button>{selectedrows.length} selected</Button>
                      <Dropdown
                        trigger={["click"]}
                        overlay={() => {
                          return (
                            <Menu>
                              <Menu.Item
                                key="0"
                                onClick={() => {
                                  const body = {
                                    id: selectedrows.join(","),
                                    active: "1",
                                  };
                                  httpPost("products/bulkactivate", body).then(
                                    (res) => {
                                      if (res["status"] === "SUCCESS") {
                                        notification.success({
                                          message: "SUCCESS",
                                          description: "activate successfully",
                                        });
                                      }
                                    }
                                  );
                                }}
                              >
                                Activate
                              </Menu.Item>
                              <Menu.Item
                                key="3"
                                onClick={() => {
                                  const body = {
                                    id: selectedrows.join(","),
                                    active: "0",
                                  };
                                  httpPost("products/bulkactivate", body).then(
                                    (res) => {
                                      if (res["status"] === "SUCCESS") {
                                        notification.success({
                                          message: "SUCCESS",
                                          description:
                                            "inactivate successfully",
                                        });
                                      }
                                    }
                                  );
                                }}
                              >
                                Inactive
                              </Menu.Item>
                              <Menu.Item
                                key="4"
                                onClick={() => {
                                  const body = {
                                    id: selectedrows.join(","),
                                  };
                                  httpPost("products/bulkarchive", body).then(
                                    (res) => {
                                      if (res["status"] === "SUCCESS") {
                                        notification.success({
                                          message: "SUCCESS",
                                          description: "archived successfully",
                                        });
                                      }
                                    }
                                  );
                                }}
                              >
                                Archived/Delete product
                              </Menu.Item>
                              <Menu.Item
                                key="5"
                                onClick={() => {
                                  setshowcollectionmodal(true);
                                }}
                              >
                                Add to collection
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  setshowcollectionmodal(true);
                                }}
                                key="6"
                              >
                                Remove from collection
                              </Menu.Item>
                            </Menu>
                          );
                        }}
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <Button>
                            More Action <DownOutlined />
                          </Button>
                        </a>
                      </Dropdown>
                    </>
                  ) : null}
                </Col>
              </Row>
              <div className="table-responsive">
                <Table
                  dataSource={products}
                  pagination={false}
                  rowSelection={rowSelection}
                  className="ant-border-space"
                >
                  <Column
                    title="PRODUCT"
                    dataIndex="product_name"
                    key="product_name"
                    render={(val, value) => {
                      return (
                        <Avatar.Group>
                          <Avatar
                            className="shape-avatar"
                            shape="circle"
                            size={40}
                            src={value["primary_image"]}
                          ></Avatar>
                          <div className="avatar-info">
                            <Title level={5}>{value["product_name"]}</Title>
                            <p>{value["sub_title"]}</p>
                          </div>
                        </Avatar.Group>
                      );
                    }}
                  />
                  <Column
                    title="BRAND"
                    render={(val, value) => {
                      return (
                        <div className="author-info">
                          <Title level={5}>{value["brand_name"]}</Title>
                        </div>
                      );
                    }}
                  />
                  <Column
                    title="PRODUCT PRICE"
                    render={(val, value) => {
                      return (
                        <div className="author-info">
                          <Title level={5}>{value["product_price"]}</Title>
                        </div>
                      );
                    }}
                    sorter={(a, b) => {
                      return a.product_price - b.product_price;
                    }}
                  />
                  <Column
                    title="SELLING PRICE"
                    render={(val, value) => {
                      return (
                        <div className="author-info">
                          <Title level={5}>{value["selling_price"]}</Title>
                        </div>
                      );
                    }}
                  />
                  <Column
                    title="QUANTITY"
                    render={(val, value) => {
                      return (
                        <div className="ant-employed">
                          <Title level={5}>{value["quantity"]}</Title>
                        </div>
                      );
                    }}
                    sorter={(a, b) => {
                      return a.quantity - b.quantity;
                    }}
                  />
                  <Column
                    title="STATUS"
                    render={(val, value) => {
                      return (
                        <>
                          <div
                            className={
                              value["active"] === 1
                                ? "order-status green-bg"
                                : "order-status red-bg"
                            }
                          >
                            {value["active"] === 1 ? "Active" : "Inactive"}
                          </div>
                        </>
                      );
                    }}
                  />
                  <Column
                    title="Action"
                    key="action"
                    render={(text, record) => (
                      <Space size="middle">
                        <a
                          onClick={() => {
                            httpGet(`products/quickview?id=${record.id}`).then(
                              (res) => {
                                if (res["status"] === "SUCCESS") {
                                  dispatch(setquickviewdata(res["data"]));
                                  dispatch(setshowquickview(true));
                                }
                              }
                            );
                          }}
                        >
                          Quick View
                        </a>
                        <a
                          onClick={() => {
                            console.log(record);
                            history.push({
                              pathname: "/product",
                              state: { productID: record["id"] },
                            });
                          }}
                        >
                          Edit{" "}
                        </a>
                        <a>Delete</a>
                      </Space>
                    )}
                  />
                </Table>
              </div>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col xs={12}>
            <Pagination
              current={currentPage}
              defaultPageSize={items}
              defaultCurrent={currentPage}
              total={totalItem}
              onChange={(value) => {
                setcurrentPage(value - 1);
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
