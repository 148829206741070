import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lenstypename: "",
  featuretypeName: "",
  colorname: "",
  brandname: "",
  wishlist: [],
  quickView: {},
  shippingaddress: {},
  viewshippingaddrsss: false,
  showquickview: false,
};

export const addproductSlice = createSlice({
  name: "addproductform",
  initialState,
  reducers: {
    setlenstypename: (state, action) => {
      state.lenstypename = action.payload;
    },
    setfeaturename: (state, action) => {
      state.featuretypeName = action.payload;
    },
    setcolorname: (state, action) => {
      state.colorname = action.payload;
    },
    setbrandname: (state, action) => {
      state.brandname = action.payload;
    },
    setwishlist: (state, action) => {
      state.wishlist = action.payload;
    },
    setquickviewdata: (state, action) => {
      state.quickView = action.payload;
    },
    setshippingaddress: (state, action) => {
      state.shippingaddress = action.payload;
    },
    setshowquickview: (state, action) => {
      state.showquickview = action.payload;
    },
    setshowshippingaddress: (state, action) => {
      state.viewshippingaddrsss = action.payload;
    },
    incrementByAmount: (state, action) => { },
  },
});

// Action creators are generated for each case reducer function
export const { setlenstypename, setfeaturename, setcolorname, setbrandname, setwishlist, setquickviewdata, setshowquickview, setshippingaddress, setshowshippingaddress } =
  addproductSlice.actions;

export default addproductSlice.reducer;
