import { Col, Modal, Row } from "antd";
import Title from "antd/lib/typography/Title";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { setshowquickview } from "../app/features/counter";

const QuickView = () => {
  const getdata = useSelector((state) => state.addproductform.quickView);
  const showquickview = useSelector(
    (state) => state.addproductform.showquickview
  );
  const dispatch = useDispatch();
  const handleOk = () => {
    dispatch(setshowquickview(false));
  };
  const handleCancel = () => {
    dispatch(setshowquickview(false));
  };
  return (
    <>
      <Modal
        width={700}
        style={{ top: 20 }}
        visible={showquickview}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row justify="center">
          <Col xs={12}>
            <img src={getdata["primary_image"]} width="100%" />
          </Col>
        </Row>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">First</th>
              <th scope="col">Last</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Product Name</td>
              <td>{getdata["product_name"]}</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Sub title</td>
              <td>{getdata["sub_title"]}</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Product price</td>
              <td>{getdata["product_price"]}</td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>Selling price</td>
              <td>{getdata["selling_price"]}</td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>Modal Name</td>
              <td>{getdata["modal_name"]}</td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>Modal Number</td>
              <td>{getdata["modal_number"]}</td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td>Quantity</td>
              <td>{getdata["quantity"]}</td>
            </tr>
            <tr>
              <th scope="row">8</th>
              <td>Size</td>
              <td>{getdata["size"]}</td>
            </tr>
            <tr>
              <th scope="row">9</th>
              <td>Brand</td>
              <td>{getdata["brand_name"]}</td>
            </tr>
            <tr>
              <th scope="row">10</th>
              <td>Gender</td>
              <td>{getdata["gender"]}</td>
            </tr>
            <tr>
              <th scope="row">11</th>
              <td>Created at</td>
              <td>
                <Moment fromNow ago>
                  {getdata["created_at"]}
                </Moment>{" "}
                ago
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
    </>
  );
};

export default QuickView;
