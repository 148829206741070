import { Breadcrumb, Button, Col, Row } from "antd";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactToPrint from 'react-to-print';
import { httpGet } from "../app/networkUtils/networkUtils";
import "../assets/styles/main.css";

export const PrintOrder = (props) => {
    const history = useHistory();
    const componentRef = useRef();
    useEffect(()=>{
       
    },[])
    return (<>
        <div>
        <Row>
          <Breadcrumb>
            <BreadcrumbItem>
              <a href="/dashboard">Home</a>
            </BreadcrumbItem>
            <BreadcrumbItem> <a
                onClick={() => {
                  history.push("/orders");
                }}
              >
                Orders
              </a></BreadcrumbItem>
            <BreadcrumbItem>Order printer</BreadcrumbItem>
          </Breadcrumb>
        </Row>
            <ReactToPrint
                trigger={() => <Button className="primary">Print this out!</Button>}
                content={() => componentRef.current}
            />
            <ComponentToPrint ref={componentRef} id = {new URLSearchParams(props.location.search).get("id")} />
        </div>
    </>)
}

const ComponentToPrint = React.forwardRef((props, ref) => {
    const [orderData, setorderData] = useState({});
    useEffect(() => {
        getData(props.id);
    }, [])
    const getData = (id) => {
        httpGet(`orders/orderprint?orderID=${id}`).then((res) => {
            setorderData(res["data"]);
        })
    }
    return (
        <>

            <Row ref={ref} justify="center">
                <Col xs={12}>
                    <div className="ui-card__section"><div className="ui-type-container">        <div className="print-preview">
                        <div id="content-header" className="printer-preview-content">
                            <div style={{ textAlign: "center" }}><center>
                                <img src="https://kareye.com/static/media/Logo.8a773999f6a38f61ef5674a0a9851e7b.svg" width="200" />
                            </center></div>
                            <hr />
                            <p style={{ float: "right", textAlign: "right", margin: "0" }}>
                                04/11/22<br />
                                Invoice for #1619
                            </p>

                            <div style={{ margin: "0 0 1.5em 0" }}>
                                <strong style={{ fontSize: "2rem" }}>Kareye</strong><br />
                                BLACKBOX Global Luxury Group DMCC<br />
                                TRN #100607816400003 <br />
                                Dubai DU<br />
                                United Arab Emirates
                            </div>

                            <hr />
                            <h3 style={{ margin: "0 0 1em 0" }}>Item Details</h3>

                            <table class="table-tabular" style={{ margin: "0 0 1.5em 0" }}>
                                <thead>
                                    <tr>
                                        <th>Quantity</th>
                                        <th>Item</th>
                                        <th>SKU</th>

                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>{orderData["quantity"]} x</td>
                                        <td><b>{orderData["name"]}</b></td>
                                        <td>{orderData["modal_number"]}</td>

                                        <td>

                                            AED {orderData["order_price"]}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>


                            <h3 style={{ margin: "0 0 1em 0" }}>Payment Details</h3>

                            <table className="table-tabular" style={{ margin: "0 0 1.5em 0" }}>
                                <tbody><tr>
                                    <td>Subtotal price:</td>
                                    <td>AED {orderData["quantity"] * orderData["order_price"]}</td>
                                </tr>

                                    <tr>
                                        <td>Total tax:</td>
                                        <td>AED 0</td>
                                    </tr>

                                    <tr>
                                        <td>Shipping:</td>
                                        <td>AED 0</td>
                                    </tr>

                                    <tr>
                                        <td><strong>Total price:</strong></td>
                                        <td><strong>AED {orderData["quantity"] * orderData["order_price"]}</strong></td>
                                    </tr>

                                    {/* <tr>
                                        <td><strong>Total paid:</strong></td>
                                        <td><strong>$0</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Outstanding Amount:</strong></td>
                                        <td><strong>$175</strong></td>
                                    </tr> */}

                                </tbody></table>

                            <h3 style={{ margin: "0 0 1em 0" }}>Shipping Details</h3>
                            <div style={{ margin: "0 0 1em 0", padding: "1rem", border: "1px solid black" }}>
                                <strong>{orderData["user_name"]}</strong><br />

                                {orderData["address1"]}<br />
                                {orderData["city"]}<br />
                                {orderData["state"]}<br />
                                {orderData["country"]}<br />
                                {orderData["mobile_no"]}
                            </div>

                        </div>
                    </div>
                    </div>
                    </div>
                </Col>
            </Row>
        </>
    );
})